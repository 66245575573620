import instance from "./_axiosConfig";

const api = instance;

const login = async (data) => {
  try {
    const response = await api.post("/auth/login", data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const loginAlternative = async (data) => {
  try {
    const response = await api.post("/auth/login/alternative", data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const signup = async (data) => {
  try {
    const response = await api.post("/user", data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const deleteUser = async (idUser) => {
  try {
    const response = await api.delete(`/user/${idUser}`, {
      headers: {
        Authorization: localStorage.getItem("userAuth"),
      },
    });
    return response.data.user;
  } catch (error) {
    return error.response;
  }
};
const updateUser = async (idUser, data) => {
  try {
    const response = await api.put(`/user/${idUser}`, data, {
      headers: {
        Authorization: localStorage.getItem("userAuth"),
      },
    });
    return response.data.user;
  } catch (error) {
    return error.response;
  }
};
const getLoggedUser = async () => {
  try {
    const response = await api.get("/user/perfil", {
      headers: {
        Authorization: localStorage.getItem("userAuth"),
      },
    });
    return response.data.user;
  } catch (error) {
    return error.response;
  }
};

const sendAlternativePayment = async (data) => {
  try {
    const response = await api.post("/user/alternativePayment", data);
    return response.data.user;
  } catch (error) {
    return error.response;
  }
};

export {
  login,
  signup,
  deleteUser,
  getLoggedUser,
  loginAlternative,
  updateUser,
  sendAlternativePayment,
};
