/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  IconButton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "@chakra-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import propTypes from "prop-types";
import useUploadFileS3 from "hooks/useUploadFileS3";
import notificationDisplay from "utils/notificationDisplay";
import { updateTrainer } from "services/trainer";
import { uploadFile } from "services/files";
import generateId from "helpers/generateId";
import { addInfo } from "reducers/user.reducer";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectInput from "components/common/SelectInput";
import { loading, loaded } from "reducers/loader.reducer";
import image from "assets/icons/ico_photo.svg";
import { transformEditProfileData } from "utils/transformData";
import useUpdateTrainer from "hooks/useUpdateTrainer";
import { inputStyle, selectStyle } from "theme/commonStyles";
import styles from "./Edit.module.scss";
import styles2 from "./Profile.module.scss";
import TextInput from "../common/TextInput";

const SPECIALITY_OPTIONS = [
  "personal_trainer",
  "strength_coach",
  "athletic_trainer",
  "sports_coach",
  "other",
];
const JOB_DESCRIPTION_OPTIONS = ["fulltime", "independent", "strength", "other"];

const Edit = ({ setEdit, edit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { control, register, watch, handleSubmit } = useForm();
  const trainer = useSelector((state) => state.trainer);
  const user = useSelector((state) => state.user);
  const [picture, setPicture] = useState(null);
  const [logo, setLogo] = useState(null);
  const [otherSpeciality, setOtherSpeciality] = useState("");
  const [otherJob, setOtherJob] = useState("");
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [loadingPic, setLoadingPic] = useState(false);

  useEffect(() => {
    if (SPECIALITY_OPTIONS.indexOf(user.speciality) < 0) {
      setOtherSpeciality(user.speciality);
    }
    if (JOB_DESCRIPTION_OPTIONS.indexOf(user.job_description) < 0) {
      setOtherJob(user.job_description);
    }
    setPicture(user.pic);
    setLogo(user.logo);
  }, []);

  const uploadFileLogo = async (file) => {
    try {
      const formData = new FormData();
      formData.append("imagen", file);
      setLoadingLogo(true);
      const response = await uploadFile(formData);
      if (response.data) console.log(response);
      setLogo(response.imagen);
      setLoadingLogo(false);
    } catch (error) {
      console.log(error);
    }
  };
  const uploadFilePicture = async (file) => {
    try {
      setLoadingPic(true);
      // Get the file extension (e.g., .jpg, .png)
      const fileExtension = file.name.split(".").pop();

      // Create a new file name with the UUID and original extension
      const newFileName = `${generateId()}.${fileExtension}`;

      const reader = new FileReader();
      reader.onload = async () => {
        const newFile = new File([reader.result], newFileName, { type: file.type });
        const formData = new FormData();
        formData.append("imagen", newFile);
        const response = await uploadFile(formData);
        if (response.data) console.log(response);
        setPicture(response.imagen);
        setLoadingPic(false);
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmit = async (data) => {
    dispatch(loading());
    /* if (picture) {
      setPicture(await uploadFileS3.mutateAsync(picture));
    } */
    /* if (logo) {
      const res = await uploadFile(logo);
      setLogo();
    } */
    // if (picLogo !== null) {
    //   logoresponse = await uploadFileS3.mutateAsync(picLogo, 'imagen-de-prueba');
    // }
    const newData = {
      ...data,
      show_certifications: user.showField.certifications,
      show_job_description: user.showField.job_description,
      show_phone: user.showField.phone,
      show_address: user.showField.address,
      show_speciality: user.showField.speciality,
    };
    if (data.speciality === "other") {
      newData.speciality = otherSpeciality;
    }
    if (data.job_description === "other") {
      newData.job_description = otherJob;
    }
    try {
      const response = await updateTrainer(
        trainer._id,
        transformEditProfileData(newData, newData.name, picture, logo),
      );
      if (response.data) throw new Error(response.data.error);
      dispatch(addInfo({ ...user, ...response.trainer }));
      dispatch(loaded());
      setEdit(!edit);
    } catch (exception) {
      // notificationDisplay(dispatch, exception.message, "error");
      dispatch(loaded());
    }
    dispatch(loaded());
  };
  return (
    <>
      <div className={styles2.infoContainer}>
        <div className={styles2.card}>
          <div className={styles.imagesContainer}>
            <div className={`${styles.avatarContainer} ${loadingPic ? styles.loading : ""}`}>
              <p>{t("picture_profile")}</p>

              <div className={styles.avatarWrapper}>
                {picture ? (
                  <img
                    className={`${styles.profilePic} pic`}
                    src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${picture}`}
                    alt=""
                  />
                ) : (
                  <img className={`${styles.profilePic} pic-logo`} src={image} alt="" />
                )}
                <div
                  className={styles.uploadButton}
                  onClick={() => document.querySelector(".file").click()}
                  role="button"
                  tabIndex={0}>
                  <i className={`fa fa-arrow-circle-up ${styles.arrow}`} aria-hidden="true" />
                </div>
                <input
                  accept="image/*"
                  className={`${styles.fileUpload} file`}
                  type="file"
                  onChange={(e) => uploadFilePicture(e.target.files[0])}
                />
              </div>
              <div className={styles.remove}>
                {picture ? (
                  <IconButton
                    aria-label="delete"
                    icon={<DeleteIcon fontSize="inherit" />}
                    colorScheme="red"
                    size="xs"
                    isRound
                    onClick={() => setPicture("")}
                  />
                ) : null}
              </div>
            </div>
            <div className={`${styles.avatarContainer} ${loadingLogo ? styles.loading : ""}`}>
              <p>{t("picture_logo")}</p>
              <div className={styles.avatarWrapper}>
                {logo ? (
                  <img
                    className={`${styles.profilePic} pic-logo`}
                    src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${logo}`}
                    alt=""
                  />
                ) : (
                  <img className={`${styles.profilePic} pic-logo`} src={image} alt="" />
                )}
                <div
                  className={styles.uploadButton}
                  onClick={() => document.querySelector(".fileLogi").click()}
                  role="button"
                  tabIndex={0}>
                  <i className={`fa fa-arrow-circle-up ${styles.arrow}`} aria-hidden="true" />
                </div>
                <input
                  accept="image/*"
                  className={`${styles.fileUpload} fileLogi`}
                  type="file"
                  onChange={(e) => uploadFileLogo(e.target.files[0])}
                />
              </div>
              <div className={styles.remove}>
                {logo ? (
                  <IconButton
                    aria-label="delete"
                    icon={<DeleteIcon fontSize="inherit" />}
                    colorScheme="red"
                    size="xs"
                    isRound
                    onClick={() => setLogo("")}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex gridGap={4}>
              <Box flex="1 1 50%">
                <TextInput control={control} def={user.name} name="name" place={t("name")} />
              </Box>
              <Box flex="1 1 50%">
                <TextInput
                  control={control}
                  def={user.lastName}
                  name="lastName"
                  place={t("lastName")}
                  required={false}
                />
              </Box>
            </Flex>
            <TextInput control={control} def={user.phone} name="phone" place={t("phonenumber")} />
            <TextInput
              control={control}
              def={user.address.street1}
              name="street1"
              place={t("principal_address")}
            />
            <Box flex="1 1 100%">
              <FormControl>
                <FormLabel>{t("secondary_address")}</FormLabel>
                <Controller
                  control={control}
                  defaultValue={user.address.street2}
                  name="street2"
                  render={({ field }) => (
                    <Input variant="filled" type="text" {...field} {...inputStyle} />
                  )}
                />
              </FormControl>
            </Box>
            <Flex gridGap={4}>
              <TextInput control={control} def={user.address.city} name="city" place={t("city")} />
              <TextInput
                control={control}
                def={user.address.state}
                name="state"
                place={t("state")}
              />
              <TextInput control={control} def={user.address.zip} name="zip" place={t("zip")} />
            </Flex>
            <Flex gridGap={4} mb="1rem">
              <Box flex="1 1 50%">
                <FormControl>
                  <FormLabel>{t("speciality")}</FormLabel>
                  <Select
                    variant="filled"
                    {...register("speciality")}
                    defaultValue={
                      SPECIALITY_OPTIONS.indexOf(user.speciality) < 0 ? "other" : user.speciality
                    }
                    {...selectStyle}>
                    {SPECIALITY_OPTIONS.map((option, index) => (
                      <option value={option}>{t(option)}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box flex="1 1 50%">
                <FormControl>
                  <FormLabel>
                    {t("describe_another")} {t("speciality")}
                  </FormLabel>
                  <Input
                    disabled={watch("speciality") !== "other"}
                    variant="filled"
                    type="text"
                    value={otherSpeciality}
                    onChange={(e) => {
                      setOtherSpeciality(e.target.value);
                    }}
                    {...inputStyle}
                  />
                </FormControl>
              </Box>
            </Flex>
            <Flex gridGap={4}>
              <Box flex="1 1 50%">
                <FormControl>
                  <FormLabel>{t("job_description")}</FormLabel>
                  <Select
                    variant="filled"
                    {...register("job_description")}
                    defaultValue={
                      JOB_DESCRIPTION_OPTIONS.indexOf(user.job_description) < 0
                        ? "other"
                        : user.job_description
                    }
                    {...selectStyle}>
                    {JOB_DESCRIPTION_OPTIONS.map((option, index) => (
                      <option value={option}>{t(option)}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box flex="1 1 50%">
                <FormControl>
                  <FormLabel>{t("job_description")}</FormLabel>
                  <Input
                    disabled={watch("job_description") !== "other"}
                    variant="filled"
                    type="text"
                    value={otherJob}
                    onChange={(e) => {
                      setOtherJob(e.target.value);
                    }}
                    {...inputStyle}
                  />
                </FormControl>
              </Box>
            </Flex>
            <TextInput
              control={control}
              def={user.certifications}
              name="certifications"
              place={t("certifications")}
            />
            <div className={styles.btnGroup}>
              <Button variant="outline" onClick={() => setEdit(!edit)}>
                {t("cancel")}
              </Button>
              <Button colorScheme="ptRed" type="submit">
                {t("save_changes")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

Edit.propTypes = {
  setEdit: propTypes.func.isRequired,
  edit: propTypes.bool.isRequired,
};

export default Edit;
