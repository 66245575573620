/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useReducer, useEffect } from "react";
import styled from "styled-components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Draggable } from "react-beautiful-dnd";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import notificationDisplay from "utils/notificationDisplay";
import useLang from "hooks/useLang";
import generateId from "helpers/generateId";
import QuoteList from "./list";
import EditWeek from "./EditWeek/EditWeek";

const Container = styled.div`
  display: flex;
  padding: 1rem;
  border-radius: 10px;
  width: 360px;
  flex-direction: column;
  background-color: ${({ isDragging }) => (isDragging ? "#e7e7e7" : "#e7e7e7")};
  box-shadow: ${({ isDragging }) => (isDragging ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "none")};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 330px;
  background-color: ${({ isDragging }) => (isDragging ? "#e7e7e7" : "#e7e7e7")};
  transition: background-color 0.2s ease;
`;

const Column = ({
  title,
  quotes,
  index,
  isScrollable,
  isCombineEnabled,
  useClone,
  columns,
  keyRefrest,
  setColumns,
  setOrdered,
  cloneState,
  handleCancelClone,
  listDaySelected,
  handleChangeSelect,
  handleChangeRemove,
  onCloneWeek,
  cloneUniqueDay,
  setCloneUniqueDay,
  scrollHorizontalBoard,
}) => {
  const { t } = useLang();
  const titleNaN = Math.floor(index);
  const AiTitle = 1 + titleNaN;
  const [weeksfilter, setWeeksfilter] = useState([]);
  const [getLastDay, setGetLastDay] = useState(1 + quotes[quotes?.length - 1]?.day || 1);
  const [open, setopen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEdit, toggleEdit] = useReducer((prev) => !prev, false);

  useEffect(() => {
    setGetLastDay(1 + quotes[quotes.length - 1]?.day || 1);
  }, [quotes, columns, cloneState]);

  // testing
  const [infoForDelete, setInfoForDelete] = useState({
    type: "",
    week: "",
    day: "",
  });

  const handleDelete = (deleteType, info) => {
    const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
    const filterData = arrayOfObjects.filter(({ week }) =>
      deleteType === "week" ? week !== AiTitle : week === AiTitle,
    );

    setWeeksfilter(filterData);
    setInfoForDelete(() => ({ day: info?.day, week: info?.week, type: deleteType }));
    setopen(true);
  };

  const handleConfirmDelete = () => {
    try {
      const FormatDays = [...weeksfilter];
      const array = FormatDays?.map(({ week }) => week);
      const newarray = [...new Set(array)];

      toggleEdit();
      if (infoForDelete.type === "week") {
        if (quotes.length > 0) {
          const dataFilteredIndexes = newarray.map((a, idx) => idx);
          setOrdered(dataFilteredIndexes);
          setColumns((prev) => {
            const filteredPrev = prev.filter((item) => newarray.includes(item[0].week));
            const newData = filteredPrev.map((item, idx) =>
              item.map((subItem) => ({ ...subItem, week: idx + 1 })),
            );
            return prev;
          });
        } else {
          columns.splice(index, 1);
          setColumns(columns);
          setOrdered(Object.keys(columns));
        }
      }
      if (infoForDelete.type === "day") {
        setColumns((prev) => {
          const weekWithNewData = weeksfilter
            .filter((weekday) => weekday.day !== infoForDelete.day)
            .map((subItem, idx) => ({ ...subItem, day: idx + 1 }));
          if (weekWithNewData.length < 1) {
            const { categoryList, ...rest } = weeksfilter[0];
            const newObj = prev.map((item, idx) =>
              idx + 1 === weeksfilter[0].week
                ? [{ ...rest, categoryList: [], id: generateId() }]
                : item,
            );
            return newObj;
          }
          const newData = prev.map((item, idx) =>
            idx + 1 === weekWithNewData[0].week ? weekWithNewData : item,
          );
          return newData;
        });
      }
    } catch (error) {
      console.log(error);
    }
    setopen(false);
  };
  const cloneWeek = () => {
    if (quotes.length > 0) {
      const allColumns = columns.flat();
      const [{ week: currentWeek }] = quotes;
      const filteredColumns = allColumns.filter(({ week }) => week === currentWeek);
      const FormatDays = [...filteredColumns];
      onCloneWeek(filteredColumns);
    }
  };
  return (
    <Draggable draggableId={title} index={index}>
      {(provided, snapshot) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Header isDragging={snapshot.isDragging}>
            <Box
              isDragging={snapshot.isDragging}
              {...provided.dragHandleProps}
              aria-label={`${title} quote list`}>
              <Box
                borderRadius="12px"
                bg="#3a3a3a"
                p=".5rem 1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Box>
                  <Heading fontSize="sm" fontWeight="medium" color="white">
                    {t("list_week")} {AiTitle}
                  </Heading>
                </Box>
                <Box>
                  <Box
                    color="gray"
                    fontSize="sm"
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<MoreVertIcon />}
                        color="white"
                        variant="ghost"
                        colorScheme="black"
                        size="xs"
                      />
                      <MenuList>
                        <MenuItem
                          type="button"
                          onClick={() => onOpen()}
                          icon={<EditIcon />}
                          isDisabled={quotes.length <= 0}>
                          {t("edit")}
                        </MenuItem>
                        <MenuItem
                          type="button"
                          onClick={() => cloneWeek()}
                          icon={<ContentCopyIcon />}
                          isDisabled={quotes.length <= 0}>
                          {t("duplicate")}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            toggleEdit();
                            handleDelete("week");
                          }}
                          color="red"
                          icon={<DeleteIcon />}
                          isDisabled={columns.length <= 1}>
                          {t("delete")}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Header>
          <QuoteList
            listId={title}
            listType="QUOTE"
            quotes={quotes}
            internalScroll={isScrollable}
            isCombineEnabled={Boolean(isCombineEnabled)}
            useClone={Boolean(useClone)}
            titleHeader={AiTitle}
            columns={columns}
            setColumns={setColumns}
            setOrdered={setOrdered}
            handleDelete={handleDelete}
            isEdit={isEdit}
            scrollHorizontalBoard={scrollHorizontalBoard}
            thelastday={getLastDay}
            toggleEdit={toggleEdit}
            cloneState={cloneState}
            handleCancelClone={handleCancelClone}
            listDaySelected={listDaySelected}
            handleChangeSelect={handleChangeSelect}
            handleChangeRemove={handleChangeRemove}
            setCloneUniqueDay={setCloneUniqueDay}
            cloneUniqueDay={cloneUniqueDay}
          />
          {open && (
            <AlertDialog isOpen={open} onClose={() => setopen(false)}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    {t("delete")} {infoForDelete.type}
                  </AlertDialogHeader>
                  <AlertDialogBody>{t("delete_confirmation")}</AlertDialogBody>
                  <AlertDialogFooter>
                    <Button onClick={() => setopen(false)}>{t("cancel")}</Button>
                    <Button colorScheme="red" onClick={() => handleConfirmDelete()} ml={3}>
                      {t("delete")}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          )}
          {onOpen && (
            <EditWeek
              isOpen={isOpen}
              onClose={onClose}
              AiTitle={AiTitle}
              setColumns={setColumns}
              columns={columns}
              quotes={quotes}
              key={isOpen}
              isEdit={isEdit}
              toggleEdit={toggleEdit}
            />
          )}
        </Container>
      )}
    </Draggable>
  );
};

export default Column;
