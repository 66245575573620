/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import StarIcon from "@mui/icons-material/Star";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
// import MailIcon from '@mui/icons-material/Mail';
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import { useSelector } from "react-redux";
import { EditIcon } from "@chakra-ui/icons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import WcIcon from '@mui/icons-material/Wc';
import EditIcon2 from "@mui/icons-material/Edit";
import TextWithIcon from "components/clients/ClientDashboard/ClientProfile/TextWithIcon";
import BoxInfoCard from "components/clients/ClientDashboard/ClientProfile/BoxInfoCard";
import { Avatar, Button, Center, Flex, Grid, Heading, Text, Link } from "@chakra-ui/react";
import styles from "./Profile.module.scss";
import { getMemberShipActive } from "../../services/membership";

const Info = ({ setEdit, edit, showFieldPdf, setShowFieldPdf }) => {
  const data = useSelector((state) => state.user);
  const setDirection = (direction) => {
    const string = [];
    string.push(`${direction.street1}`);
    if (direction.street2) string.push(`${direction.street2}\n`);
    string.push(`${direction.city} ${direction.state}, ${direction.zip}`);
    return string;
  };

  const { t } = useTranslation();
  /*   const [membership, setMembership] = useState({});

  const getstatus = async () => {
    const response = await getMemberShipActive(data.id_user);
    setMembership(response?.membership_check);
  };

  useEffect(() => {
    getstatus();
  }, []);
 */
  return (
    <>
      <div className={styles.infoContainer}>
        <div className={styles.avatarContainer}>
          <div className={styles.avatarWrapper}>
            <img
              className={`${styles.profilePic} pic-logo`}
              src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${data?.logo || ""}`}
              alt=""
            />
          </div>
        </div>
        <div className={styles.card}>
          <Flex gridGap={4} alignItems="center">
            <Avatar
              size="xl"
              bg="gray"
              color="white"
              name={data?.name}
              alt="deault profile"
              src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${data?.pic || ""}`}
            />
            <div>
              <Heading>
                {data?.name} {data?.lastName}
              </Heading>
              <Text color="gray" fontSize="12px">
                {data.email}
              </Text>
              <p className={styles.type_account}>
                <span>{t(`${data?.membership}-sub`)}</span>
              </p>
            </div>
          </Flex>

          {data.membership && (
            <Flex gridGap={4} alignItems="center" justifyContent="flex-end">
              <Button
                as={Link}
                href={`${process.env.REACT_APP_STRIPE_BILLING_URL}?prefilled_email=${data.email}`}
                size="sm"
                variant="ghost"
                colorScheme="ptRed"
                rightIcon={<ArrowForwardIosIcon fontSize="inherit" />}
                isExternal>
                {t("billingInformation")}
              </Button>
            </Flex>
          )}
        </div>
        <div className={styles.card}>
          <TextWithIcon icon={PhoneIcon} text={data.phone} />
          <TextWithIcon icon={WorkIcon} text={t(data.job_description)} />
          <TextWithIcon icon={StarIcon} text={t(data?.speciality)} />
          <TextWithIcon icon={SchoolIcon} text={t(data.certifications)} />
          <TextWithIcon icon={LocationOnIcon} text={setDirection(data.address)} isAddress />
        </div>

        <Flex gridGap={4} justifyContent="space-between">
          <Button variant="outline" onClick={() => setShowFieldPdf(!showFieldPdf)}>
            {t("custom_pdf")}
          </Button>
          <Button colorScheme="ptRed" leftIcon={<EditIcon />} onClick={() => setEdit(!edit)}>
            {t("edit")}
          </Button>
        </Flex>
      </div>
    </>
  );
};

Info.propTypes = {
  data: propTypes.shape({
    id_user: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    address: propTypes.shape({
      state: propTypes.string.isRequired,
      city: propTypes.string.isRequired,
      street1: propTypes.string.isRequired,
      street2: propTypes.string.isRequired,
      zip: propTypes.string.isRequired,
    }).isRequired,
    speciality: propTypes.string.isRequired,
    phone: propTypes.string.isRequired,
    job_description: propTypes.string.isRequired,
    pic: propTypes.string.isRequired,
    logo: propTypes.string.isRequired,
  }).isRequired,
  setEdit: propTypes.func.isRequired,
  edit: propTypes.bool.isRequired,
  setShowFieldPdf: propTypes.func.isRequired,
  showFieldPdf: propTypes.bool.isRequired,
};

export default Info;
