import dashboard from "../../assets/icons/ico_dashboard.svg";
import clients from "../../assets/icons/ico_clients.svg";
import exercise from "../../assets/icons/ico_exercise.svg";
import assessment from "../../assets/icons/ico_assessment.svg";
import programs from "../../assets/icons/ico_programs.svg";
import stats from "../../assets/icons/ico_stats.svg";
import planning from "../../assets/icons/ico_planning.svg";
import support from "../../assets/icons/ico_support.svg";

const menu = [
  {
    name: "dashboard",
    icon: dashboard,
    url: "/user/dashboard",
  },
  {
    name: "clients",
    icon: clients,
    url: "/user/clients",
  },
  {
    name: "exercises",
    icon: exercise,
    url: "/user/exercises",
  },
  {
    name: "evaluation",
    icon: assessment,
    url: "/user/evaluation",
  },
  {
    name: "programs",
    icon: programs,
    url: "/user/programs",
  },
  {
    name: "planning",
    icon: planning,
    url: "/user/planning",
  },
  {
    name: "stats",
    icon: stats,
    url: "/user/stats",
  },
];
const optionsMenu = [
  {
    name: "customer",
    icon: support,
    url: "/user/customer",
  },
];
export { menu, optionsMenu };
