/* eslint-disable react/prop-types */
import { Button, ButtonGroup, Heading } from "@chakra-ui/react";
import useLang from "hooks/useLang";

const TypeEvaluationSelector = ({ setType, setStep, setNewMeasurement, setNewGoal }) => {
  const { t } = useLang();
  const handleSelectProgram = (type) => {
    setNewGoal(null);
    setNewMeasurement(null);
    setType(type);
    setStep((prev) => prev + 1);
  };
  return (
    <>
      <Heading mb={4}>{t("select_type_evaluation")}</Heading>
      <p>{t("select_type_evaluation_desc")}</p>
      <ButtonGroup mt={4} colorScheme="ptRed">
        <Button onClick={() => handleSelectProgram("bio")}>{t("evaluation")}</Button>
        <Button onClick={() => handleSelectProgram("goal")}>{t("goals")}</Button>
      </ButtonGroup>
    </>
  );
};
export default TypeEvaluationSelector;
