/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
// ? External Dependecies
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { useQueryClient } from "react-query";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import { Link } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// ? local Dependecies
import useLang from "hooks/useLang";
import useGetAll from "hooks/useGetAll";
import { getTrainerClients, updateClient } from "services/clients";
import notificationDisplay from "utils/notificationDisplay";
import NoInfoComponent from "components/shared/components/NoInfoComponent";

import notFound from "assets/notfound.png";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import DisplayMessage from "components/common/DisplayMessage";
import styles from "./ClientDasboard.module.css";
import ClientContainer from "./ClientContainer";
import ClientAdder from "./ClientAdder";
import ClientPlanning from "./ClientPlanning";

const ClientDashboard = () => {
  const location = useLocation();
  const myParam = location?.state?.clientInfo;
  const myParamModalAdd = location?.state?.modalAdd;
  const [searchValue, setSearchValue] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [filterType, setFilterType] = useState("all");
  const { id_user } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data, isLoading, isError, refetch } = useGetAll("clients", id_user, getTrainerClients);
  const [tab, setTab] = useState({
    isSelected: !!myParam || myParamModalAdd,
    value: myParam ? "programs" : myParamModalAdd ? "add" : "close",
    client: myParam || {},
  });
  const [clients, setClients] = useState([]);
  const { t } = useLang();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [nPages, setNPages] = useState(0);

  // functions
  const openAddClient = () => setTab({ isSelected: true, value: "add", client: {} });

  const handleUpdateClient = async (idClient, info) => {
    try {
      await updateClient(idClient, info);
      queryClient.invalidateQueries({ queryKey: "clients" });
      refetch();
      setTab({
        isSelected: false,
        value: "close",
        client: {},
      });
      notificationDisplay(dispatch, "Succesfully edited client", "success");
    } catch (error) {
      notificationDisplay(dispatch, error.message, "error");
      return console.log(error);
    }
  };

  useEffect(() => {
    setNPages(
      Math.ceil(
        clients
          ?.filter(
            ({ name, lastName }) =>
              name?.toLowerCase().includes(searchValue.toLowerCase()) ||
              lastName?.toLowerCase().includes(searchValue.toLowerCase()),
          )
          .filter((client) =>
            filterType === "all" ? true : filterType === "guest" ? client.isGuest : !client.isGuest,
          ).length / recordsPerPage,
      ),
    );
  }, [clients, filterType, searchValue]);

  useEffect(() => {
    if (data?.length < 1 || !Array.isArray(data)) return;
    setClients(data);
    /* const sidebar = document.getElementsByClassName("sidebar")[0];
    if (sidebar) sidebar.setAttribute("class", "sidebar collapse");
    return () => {
      if (sidebar) sidebar.setAttribute("class", "sidebar");
    }; */
  }, [data]);

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) setCurrentPage(nPages);
  };
  if (isError) return <DisplayMessage message={t("something_wrong")} />;
  if (isLoading)
    return (
      <>
        <>
          <Flex gridGap={4} justifyContent="space-between">
            <Skeleton height="40px" width="60%" />
            <Skeleton height="40px" w="150px" />
          </Flex>
          <Flex gridGap={4} flexDirection="column" mt={4}>
            <Skeleton height="60px" w="100%" />
            <Skeleton height="60px" w="100%" />
            <Skeleton height="60px" w="100%" />
            <Skeleton height="60px" w="100%" />
            <Skeleton height="60px" w="100%" />
          </Flex>
        </>
      </>
    );
  return (
    <>
      <section className={styles.container}>
        {!tab.isSelected && (
          <header className={styles.header}>
            <>
              <Box w="50%">
                <Flex gridGap={4} alignItems="center">
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Search2Icon color="gray.600" />
                    </InputLeftElement>
                    <Input
                      variant="outline"
                      type="text"
                      placeholder="Search..."
                      value={searchValue}
                      onChange={({ target }) => setSearchValue(target.value)}
                    />
                  </InputGroup>
                  <Button
                    colorScheme="blackAlpha"
                    size="xs"
                    marginBottom="0px"
                    leftIcon={<FilterAltIcon fontSize="inherit" />}
                    onClick={() => setShowFilters(!showFilters)}>
                    {t("filters")}
                  </Button>
                </Flex>

                <Box mt={4} minH="42px">
                  <Flex gridGap={4} flexWrap="wrap">
                    {showFilters ? (
                      <>
                        <Button
                          colorScheme={filterType === "all" ? "green" : "gray"}
                          size="xs"
                          onClick={() => setFilterType("all")}>
                          {t("showAll")}
                        </Button>
                        <Button
                          colorScheme={filterType === "clients" ? "green" : "gray"}
                          size="xs"
                          onClick={() => setFilterType("clients")}>
                          {t("onlyClients")}
                        </Button>
                        <Button
                          colorScheme={filterType === "guest" ? "green" : "gray"}
                          size="xs"
                          onClick={() => setFilterType("guest")}>
                          {t("onlyGuests")}
                        </Button>
                      </>
                    ) : null}
                  </Flex>
                </Box>
              </Box>

              <Button
                bg="#f07300"
                border="none"
                _hover={{ bg: "#ff9a3e" }}
                borderRadius="50px"
                color="white"
                onClick={openAddClient}>
                <AddIcon />
                {t("add_client")}
              </Button>
            </>
          </header>
        )}
        {!tab.isSelected && clients?.length < 1 && (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" gridGap={8}>
            <NoInfoComponent />
            <Button colorScheme="ptRed" onClick={openAddClient}>
              <AddIcon />
              {t("add_client")}
            </Button>
          </Flex>
        )}
        {!tab.isSelected && clients.length > 0 && (
          <>
            <TableContainer>
              <Table variant="striped" className={styles.clientTable}>
                <Thead>
                  <Tr>
                    <Th textTransform="uppercase">{t("clients")}</Th>
                    <Th textTransform="uppercase" textAlign="center">
                      {t("status")}
                    </Th>
                    <Th textTransform="uppercase">{t("programs")}</Th>
                    <Th textTransform="uppercase" textAlign="end">
                      {t("details")}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {clients
                    ?.filter(({ name, lastName }) =>
                      new RegExp(searchValue, "gi").test(`${name} ${lastName}`),
                    )
                    ?.filter(({ isGuest }) =>
                      filterType === "all" ? true : filterType === "guest" ? isGuest : !isGuest,
                    )
                    .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
                    .slice(searchValue !== "" ? 0 : indexOfFirstRecord, indexOfLastRecord)
                    ?.map((client) => (
                      <Tr>
                        <Td cursor="pointer">
                          <Flex alignItems="center" gridGap={4}>
                            <Avatar
                              name={`${client.name} ${client?.lastName}`}
                              bg="gray"
                              color="white"
                              alt="deault profile"
                              src={client.pic || ""}
                              style={{ pointerEvents: "none" }}
                              width="40px"
                              height="40px"
                              fontSize="30px"
                              onClick={() =>
                                setTab({
                                  value: "profile",
                                  isSelected: true,
                                  client,
                                })
                              }
                            />
                            <Box className={styles.clientName}>
                              <Text
                                fontWeight="bold"
                                onClick={() =>
                                  setTab({
                                    value: "profile",
                                    isSelected: true,
                                    client,
                                  })
                                }
                                id="profile">
                                {client.name} {client?.lastName}
                              </Text>
                              <Badge
                                id="profile"
                                className={styles.badge}
                                bg={!client.isGuest ? "#6bca21" : "#b42519"}
                                color="white"
                                borderRadius="20px"
                                padding="0px 6px"
                                fontSize="10px"
                                size="xs">
                                {!client.isGuest ? t("client") : t("guest")}
                              </Badge>
                            </Box>
                          </Flex>
                        </Td>
                        <Td cursor="pointer">
                          <Flex justifyContent="center">
                            <Badge
                              id="profile"
                              bg={!client.isGuest ? "#6bca21" : "#b42519"}
                              color="white"
                              borderRadius="20px"
                              padding="0px 6px"
                              fontSize="10px"
                              size="xs"
                              onClick={() =>
                                setTab({
                                  value: "profile",
                                  isSelected: true,
                                  client,
                                })
                              }>
                              {!client.isGuest ? t("client") : t("guest")}
                            </Badge>
                          </Flex>
                        </Td>
                        <Td>
                          <Flex alignItems="center" gridGap={4}>
                            <Button
                              colorScheme="blackAlpha"
                              variant="outline"
                              as={Link}
                              to={`evaluation/${client.name}___${client._id}`}
                              size="sm"
                              borderRadius="20px"
                              leftIcon={<AccessibilityIcon fontSize="inherit" />}>
                              {t("new_program_assessments")}
                            </Button>
                            <Button
                              colorScheme="blackAlpha"
                              size="sm"
                              borderRadius="20px"
                              leftIcon={<AssignmentOutlinedIcon fontSize="inherit" />}
                              disabled={client.isGuest && client?.customWorkouts?.routinecount <= 0}
                              onClick={() =>
                                setTab({
                                  value: "programs",
                                  isSelected: true,
                                  client,
                                })
                              }>
                              {t(
                                !client.isGuest && client?.customWorkouts?.routinecount <= 0
                                  ? "create_new_program"
                                  : "programs",
                              )}
                            </Button>
                          </Flex>
                        </Td>
                        <Td>
                          <Flex alignItems="center" gridGap={4} justifyContent="end">
                            <Tooltip label={t("history")}>
                              <IconButton
                                colorScheme="gray"
                                color="#808080"
                                isRound
                                variant="solid"
                                aria-label="Done"
                                size="sm"
                                icon={<PendingActionsIcon />}
                                disabled={client?.customWorkouts?.routinecount <= 0}
                                onClick={() =>
                                  setTab({
                                    value: "history",
                                    isSelected: true,
                                    client,
                                  })
                                }
                              />
                            </Tooltip>
                            <ClientPlanning client={client} />
                            <Tooltip label={t("showProfile")}>
                              <IconButton
                                colorScheme="gray"
                                color="#808080"
                                id="profile"
                                isRound
                                variant="solid"
                                aria-label="Done"
                                size="sm"
                                icon={<FolderSharedIcon />}
                                onClick={() =>
                                  setTab({
                                    value: "profile",
                                    isSelected: true,
                                    client,
                                  })
                                }
                              />
                            </Tooltip>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
            {!isLoading && (
              <>
                {clients
                  ?.filter(({ name, lastName }) =>
                    new RegExp(searchValue, "gi").test(`${name} ${lastName}`),
                  )
                  ?.filter(({ isGuest }) =>
                    filterType === "all" ? true : filterType === "guest" ? isGuest : !isGuest,
                  )
                  .sort((a, b) => (a.created_at > b.created_at ? -1 : 1)).length <= 0 && (
                  <Flex flexDirection="column" alignItems="center" justifyContent="center">
                    <Image src={notFound} w="10rem" alt="not found" />
                    <Heading> {t("notFoundClient")}</Heading>
                  </Flex>
                )}
              </>
            )}
            <nav>
              <ul className="pagination">
                <li>
                  <IconButton
                    isRound
                    variant="ghost"
                    colorScheme="blackAlpha"
                    aria-label="Done"
                    fontSize="20px"
                    disabled={currentPage === 1}
                    icon={<FirstPageIcon />}
                    onClick={goToFirstPage}
                  />
                </li>
                <li>
                  <IconButton
                    isRound
                    variant="ghost"
                    colorScheme="blackAlpha"
                    aria-label="Done"
                    fontSize="20px"
                    icon={<ArrowBackIosIcon />}
                    onClick={goToPrevPage}
                    disabled={currentPage === 1}
                  />
                </li>
                <li>
                  {searchValue !== "" ? 1 : currentPage}/{nPages <= 0 ? 1 : nPages}
                </li>
                <li>
                  <IconButton
                    isRound
                    variant="ghost"
                    colorScheme="blackAlpha"
                    aria-label="Done"
                    fontSize="20px"
                    icon={<ArrowForwardIosIcon />}
                    onClick={goToNextPage}
                    disabled={currentPage >= nPages}
                  />
                </li>
                <li>
                  <IconButton
                    isRound
                    variant="ghost"
                    colorScheme="blackAlpha"
                    aria-label="Done"
                    fontSize="20px"
                    disabled={currentPage === nPages}
                    icon={<LastPageIcon />}
                    onClick={goToLastPage}
                  />
                </li>
              </ul>
            </nav>
          </>
        )}
        {tab.isSelected && tab.value !== "add" && (
          <ClientContainer
            client={tab.client}
            tab={tab}
            setTab={setTab}
            allClient={clients}
            handleUpdateClient={handleUpdateClient}
            setClients={setClients}
          />
        )}
        {tab.isSelected && tab.value === "add" && <ClientAdder setTab={setTab} />}
      </section>
    </>
  );
};

export default ClientDashboard;
