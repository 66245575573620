/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Document,
  PDFDownloadLink,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import useLang from "hooks/useLang";
import { getMeasurementsByUser } from "services/evaluations";
import Logo from "../assets/1.png";
import WorldIcons from "../assets/icons/world.png";
import TopImage from "../assets/headerPdf.png";

const RenderMainContent = ({ evaluation, datatrainer, clientInfo, measurements }) => {
  const { t, lang } = useLang();
  const styles = StyleSheet.create({
    widthFull: {
      width: "100%",
    },
    headerTitleInformation: {
      fontSize: 10,
      color: "white",
      paddingLeft: 10,
      paddingVertical: 3,
      backgroundColor: "black",
      borderTop: "2px solid black",
      textTransform: "uppercase",
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
    },
    headerInformation: {
      display: "flex",
      flexDirection: "row",
    },
    clientContainer: {
      width: "40%",
      fontSize: 8,
      height: 170,
      border: "1.5px solid black",
      borderRadius: 15,
    },
    containerFields: {
      paddingVertical: 4,
    },
    clientBox: {
      display: "flex",
      paddingHorizontal: 10,
      paddingVertical: 3,
      flexDirection: "row",
      flexWrap: "wrap",
    },
    clientLabel: {
      width: "30%",
      paddingVertical: 3,
    },
    clientInformation: {
      backgroundColor: "#f6f6f6",
      padding: 3,
      borderBottom: "1px solid #b42519",
      width: "70%",
      borderRadius: 5,
    },
    trainerContainer: {
      width: "55%",
      fontSize: 8,
      height: 170,
      border: "1.5px solid black",
      borderRadius: 15,
    },
    trainerMainBox: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    trainerBox: {
      width: "29%",
      height: 100,
      padding: 5,
      margin: "auto",
    },
    trainerBoxContactInfo: {
      width: "67%",
      height: 170,
      padding: 5,
    },
    divider: {
      height: "100%",
      width: "0.3%",
      backgroundColor: "black",
    },
    trainertBoxField: {
      display: "flex",
      paddingVertical: 2,
      flexDirection: "row",
      flexWrap: "wrap",
    },
    trainertLabel: {
      width: "32%",
      paddingVertical: 3,
    },
    trainertInformation: {
      backgroundColor: "#f6f6f6",
      padding: 3,
      borderBottom: "1px solid #b42519",
      width: "68%",
      borderRadius: 5,
    },
    logosMedia: {
      width: 10,
    },
    imageLogoTrainer: {
      position: "relative",
    },
    imagenLogo: {
      position: "absolute",
      left: 0,
      top: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      right: 0,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 5,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "gray",
    },
    footer: {
      position: "absolute",
      width: "100%",
      bottom: 0,
      height: 40,
    },
    footerItem: {
      backgroundColor: "#383838",
      borderBottom: "3px solid #b42519",
      width: "100%",
      height: 25,
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "center",
      alignItems: "center",
    },
    containerMain: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    },
    containerHeader: {
      border: "1.2px solid black",
      marginTop: 25,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 15,
    },
    headerWeek: {
      backgroundColor: "#b42519",
      color: "white",
      fontSize: 10,
      paddingVertical: 5,
      width: "50%",
      top: -1.5,
      left: -2,
      paddingLeft: 10,
      borderTopRightRadius: 25,
      borderBottomRightRadius: 25,
    },
    contaienrMapDays: {
      display: "flex",
      flexDirection: "column",
      marginHorizontal: 10,
      marginVertical: 7,
    },
    dayStyles: {
      width: "30%",
      paddingHorizontal: 6,
      paddingVertical: 4,
      borderRadius: 5,
      textAlign: "center",
      marginVertical: 5,
      fontSize: 8,
      backgroundColor: "black",
      color: "white",
    },
    noHaveObservation: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "14",
      color: "gray",
      paddingVertical: 20,
      width: "100%",
    },
    boxGoalsData: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      fontSize: 7,
      marginTop: 5,
    },
    containerWhere: {
      width: "70%",
      display: "flex",
      flexDirection: "row",
    },
    fieldWhere: {
      backgroundColor: "#f6f6f6",
      padding: 3,
      borderBottom: "1px solid #b42519",
      width: "40%",
      borderRadius: 5,
    },
  });

  return (
    <Document>
      <Page size="A4" orientation="portrait" wrap={false}>
        <View style={styles.widthFull}>
          <Image src={TopImage} style={{ width: "100%" }} />
        </View>
        <View style={{ marginHorizontal: 30, marginTop: 30, marginBottom: 40 }}>
          <View style={styles.headerInformation}>
            <View style={styles.clientContainer}>
              <View style={styles.headerTitleInformation}>
                <Text>{t("client")}</Text>
              </View>
              <View style={styles.containerFields}>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>{t("table_name")}</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>
                      {`${clientInfo?.name} `} {clientInfo?.lastName}
                    </Text>
                  </View>
                </View>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>{t("phonenumber")}</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>{clientInfo?.phone}</Text>
                  </View>
                </View>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>{t("profile_address")}</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>{`${clientInfo?.address?.state}, ${clientInfo.address?.city}, ${
                      clientInfo?.address?.street1
                    } ${clientInfo?.address?.street2 ?? ""}, ${clientInfo?.address?.zip}`}</Text>
                  </View>
                </View>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>Email</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>{clientInfo?.email}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: "5%" }} />
            <View style={styles.trainerContainer}>
              <View style={styles.headerTitleInformation}>
                <Text>
                  {t("trainer")} {`${datatrainer?.name} `} {datatrainer?.lastName}
                  {datatrainer?.showField?.speciality && `, ${t(datatrainer.speciality)}`}
                </Text>
              </View>
              <View style={styles.trainerMainBox}>
                <View style={styles.trainerBoxContactInfo}>
                  <View style={styles.trainertBoxField}>
                    <View style={styles.trainertLabel}>
                      <Text>Email</Text>
                    </View>
                    <View style={styles.trainertInformation}>
                      <Text>{datatrainer.email}</Text>
                    </View>
                  </View>
                  {datatrainer?.showField?.phone && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("phonenumber")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{datatrainer.phone}</Text>
                      </View>
                    </View>
                  )}
                  {datatrainer?.showField?.address && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("profile_address")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{`${datatrainer?.address.state}, ${datatrainer.address.city}, ${
                          datatrainer.address.street1
                        } ${datatrainer.address.street2 ?? "" ?? ""}, ${
                          datatrainer.address.zip
                        }`}</Text>
                      </View>
                    </View>
                  )}
                  {datatrainer?.showField?.certifications && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("certifications")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{datatrainer.certifications}</Text>
                      </View>
                    </View>
                  )}
                  {datatrainer?.showField?.job_description && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("job_description")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{t(datatrainer.job_description)}</Text>
                      </View>
                    </View>
                  )}
                </View>
                <View style={styles.divider} />
                <View style={styles.trainerBox}>
                  <View style={styles.imageLogoTrainer}>
                    <View style={styles.imagenLogo}>
                      <Image
                        src={
                          datatrainer?.pic
                            ? `${process.env.REACT_APP_FILE_API_URL}/file?file=${datatrainer?.pic}`
                            : datatrainer?.logo
                            ? `${process.env.REACT_APP_FILE_API_URL}/file?file=${datatrainer?.logo}`
                            : Logo
                        }
                        style={{ width: 50 }}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View>
            <View style={styles.containerMain}>
              <View style={styles.containerHeader}>
                <View
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    flexDirection: "row",
                    width: "100%",
                  }}>
                  <View style={styles.headerWeek}>
                    <Text>{t("goalForm.clientGoals")}</Text>
                  </View>
                  <View
                    style={{
                      backgroundColor: "lightgray",
                      zIndex: -10,
                      left: -13,
                      width: "15%",
                      height: "95%",
                      fontSize: 9,
                      color: "black",
                      borderTopRightRadius: 20,
                      borderBottomRightRadius: 20,
                    }}>
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 5,
                      }}>
                      <Text>
                        {moment(evaluation.created_at || Date.now()).format(
                          lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                        )}
                      </Text>
                    </View>
                  </View>
                </View>

                {/* Main Goals */}
                <View style={styles.contaienrMapDays}>
                  <View
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      flexDirection: "row",
                      width: "100%",
                    }}>
                    <View style={styles.dayStyles}>
                      <Text>{t("goalForm.primaryGoals")}</Text>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.primaryGoals")}</Text>
                    </View>
                    <View style={styles.clientInformation}>
                      <Text>{evaluation.primary_goals.join(", ")}</Text>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.timeFrame")}</Text>
                    </View>
                    <View style={styles.clientInformation}>
                      <Text>{evaluation.time_frame.join(", ")}</Text>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.measuresSuccess")}</Text>
                    </View>
                    <View style={styles.clientInformation}>
                      <Text>{evaluation.measures_success}</Text>
                    </View>
                  </View>
                </View>

                {/* Body composition changes */}
                <View style={styles.contaienrMapDays}>
                  <View
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      flexDirection: "row",
                      width: "100%",
                    }}>
                    <View style={styles.dayStyles}>
                      <Text>{t("goalForm.bodyComposition")}</Text>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.loseWeight")}</Text>
                    </View>
                    <View style={styles.clientInformation}>
                      <Text>{evaluation.lose_weight}</Text>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.loseInches")}</Text>
                    </View>
                    <View style={styles.containerWhere}>
                      <View style={styles.fieldWhere}>
                        <Text>{evaluation.lose_inches}</Text>
                      </View>
                      <View style={{ width: "20%", padding: 3 }}>
                        <Text>{t("goalForm.where")}</Text>
                      </View>
                      <View style={styles.fieldWhere}>
                        <Text>{evaluation.lose_inches_where}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.loseFat")}</Text>
                    </View>
                    <View style={styles.containerWhere}>
                      <View style={styles.fieldWhere}>
                        <Text>{evaluation.lose_fat}</Text>
                      </View>
                      <View style={{ width: "20%", padding: 3 }}>
                        <Text>{t("goalForm.where")}</Text>
                      </View>
                      <View style={styles.fieldWhere}>
                        <Text>{evaluation.lose_fat_where}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.gainWeight")}</Text>
                    </View>
                    <View style={styles.clientInformation}>
                      <Text>{evaluation.gain_weight}</Text>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.gainMuscle")}</Text>
                    </View>
                    <View style={styles.containerWhere}>
                      <View style={styles.fieldWhere}>
                        <Text>{evaluation.gain_muscle}</Text>
                      </View>
                      <View style={{ width: "20%", padding: 3 }}>
                        <Text>{t("goalForm.where")}</Text>
                      </View>
                      <View style={styles.fieldWhere}>
                        <Text>{evaluation.gain_muscle_where}</Text>
                      </View>
                    </View>
                  </View>
                </View>

                {/* Performance/fitness enhancement */}
                <View style={styles.contaienrMapDays}>
                  <View
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      flexDirection: "row",
                      width: "100%",
                    }}>
                    <View style={styles.dayStyles}>
                      <Text>{t("goalForm.performanceFitness")}</Text>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.activityEnhancement")}</Text>
                    </View>
                    <View style={styles.clientInformation}>
                      <Text>{evaluation.activity_enhancement}</Text>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.physicalAbilities")}</Text>
                    </View>
                    <View style={styles.clientInformation}>
                      <Text>{evaluation.physical_abilities}</Text>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.performanceComponents")}</Text>
                    </View>
                    <View style={styles.clientInformation}>
                      <Text>{evaluation.performance_components}</Text>
                    </View>
                  </View>
                  <View style={styles.boxGoalsData}>
                    <View style={styles.clientLabel}>
                      <Text>{t("goalForm.performanceQualities")}</Text>
                    </View>
                    <View style={styles.clientInformation}>
                      <Text>{evaluation.performance_qualities}</Text>
                    </View>
                  </View>
                </View>

                {/* Diagnosed Contraindications */}
                <View style={styles.contaienrMapDays}>
                  <View
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      flexDirection: "row",
                      width: "100%",
                    }}>
                    <View style={styles.dayStyles}>
                      <Text>{t("goalForm.diagnosedContraindications")}</Text>
                    </View>
                  </View>
                  {evaluation.contraindications.map((sub, i) => (
                    <View>
                      <View style={styles.boxGoalsData}>
                        <View style={styles.clientLabel}>
                          <Text>
                            {t("goalForm.condition")} {i + 1}:
                          </Text>
                        </View>
                        <View style={styles.clientInformation}>
                          <Text>{sub.condition}</Text>
                        </View>
                      </View>
                      <View style={styles.boxGoalsData}>
                        <View style={styles.clientLabel}>
                          <Text>{t("goalForm.contraindicatedMovements")}::</Text>
                        </View>
                        <View style={styles.clientInformation}>
                          <Text>{sub.contraindicate_movements}</Text>
                        </View>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </View>
            {measurements?.map((item) => (
              <View style={styles.containerMain} break>
                <View style={styles.containerHeader}>
                  <View
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      flexDirection: "row",
                      width: "100%",
                    }}>
                    <View style={styles.headerWeek}>
                      <Text>{t("measurementForm.clientMeasurement")}</Text>
                    </View>
                    <View
                      style={{
                        backgroundColor: "lightgray",
                        zIndex: -10,
                        left: -13,
                        width: "15%",
                        height: "95%",
                        borderTopRightRadius: 20,
                        color: "black",
                        fontSize: 9,
                        borderBottomRightRadius: 20,
                      }}>
                      <View
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 5,
                        }}>
                        <Text>
                          {moment(item.created_at || Date.now()).format(
                            lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>

                  {/* General information */}
                  <View style={styles.contaienrMapDays}>
                    <View
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "100%",
                      }}>
                      <View style={styles.dayStyles}>
                        <Text>{t("measurementForm.generalInformation")}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.weight")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.generalInformation.weight}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.height")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.generalInformation.height}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.bp")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.generalInformation.bp}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.hr")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.generalInformation.hr}</Text>
                      </View>
                    </View>
                  </View>

                  {/* Circunference Measurements */}
                  <View style={styles.contaienrMapDays}>
                    <View
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "100%",
                      }}>
                      <View style={styles.dayStyles}>
                        <Text>{t("measurementForm.circunferenceMeasurements")}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.arm")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.circunference.arm}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.chest")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.circunference.chest}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.abdominals")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.circunference.abdominals}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.hip")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.circunference.hip}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.thigh")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.circunference.thigh}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.calf")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.circunference.calf}</Text>
                      </View>
                    </View>
                  </View>

                  {/* Skinfold Measurements */}
                  <View style={styles.contaienrMapDays}>
                    <View
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "100%",
                      }}>
                      <View style={styles.dayStyles}>
                        <Text>{t("measurementForm.skinfoldMeasurements")}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.chest")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.skinfold?.chest}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.triceps")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.skinfold?.triceps}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.subscapula")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.skinfold?.subscapula}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.midaxillary")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.skinfold?.midaxillary}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.suprailiac")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.skinfold?.suprailiac}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.abdominal")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.skinfold?.abdominal}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.thigh")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.skinfold?.thigh}</Text>
                      </View>
                    </View>
                  </View>

                  {/* Performance Measurements */}
                  <View style={styles.contaienrMapDays}>
                    <View
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "100%",
                      }}>
                      <View style={styles.dayStyles}>
                        <Text>{t("measurementForm.performanceMeasurements")}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.vertical")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.performance.vertical}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.mbChest")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.performance.mb_chest}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.yrd300")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.performance.yrd_300}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.bench1rm")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.performance.bench_1rm}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.sq1rm")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.performance.sq_1rm}</Text>
                      </View>
                    </View>
                  </View>

                  {/* Pain Scale (1-10) */}
                  <View style={styles.contaienrMapDays}>
                    <View
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "100%",
                      }}>
                      <View style={styles.dayStyles}>
                        <Text>{t("measurementForm.painScale")}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.painScale")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.pain_scale}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.notesTherapist")}:</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.therapist_notes}</Text>
                      </View>
                    </View>
                    <View style={styles.boxGoalsData}>
                      <View style={styles.clientLabel}>
                        <Text>{t("measurementForm.notesFromTrainer")}</Text>
                      </View>
                      <View style={styles.clientInformation}>
                        <Text>{item.trainer_notes}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 4,
            }}>
            <Text
              style={{ color: "gray", fontSize: 10 }}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            />
          </View>
          <View style={styles.footerItem}>
            <Image src={WorldIcons} style={{ width: 7, marginRight: 3 }} />
            <Text style={{ color: "white", fontSize: 7 }}>Perfecttrainerapp.com</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const GeneratePdfGoals = ({ evaluation, dataClient, datatrainer, pageType }) => {
  const { t } = useLang();
  const [measurements, setMeasurements] = useState([]);

  useEffect(() => {
    const getMeasurementsClient = async () => {
      try {
        const res = await getMeasurementsByUser(dataClient._id);
        if (res.data) throw new Error(res.data.message);
        setMeasurements(res);
      } catch (exception) {
        console.log(exception);
      }
    };
    getMeasurementsClient();
  }, []);

  return (
    <PDFDownloadLink
      document={
        <RenderMainContent
          evaluation={evaluation}
          clientInfo={dataClient}
          datatrainer={datatrainer}
          measurements={measurements}
        />
      }
      fileName={`${dataClient.name} - ${t("goals")}`}>
      {({ loading }) =>
        loading ? (
          <Button
            isLoading={loading}
            loadingText={t("generasting_pdf")}
            size={pageType ? "md" : "xs"}
            spinnerPlacement="start">
            {t("generasting_pdf")}
          </Button>
        ) : (
          <Button
            bg="#b42519"
            border="1px solid #b42519"
            color="white"
            size={pageType ? "md" : "xs"}
            _hover={{ bg: "#b42519", border: "1px solid #b42519" }}>
            <FileDownloadIcon fontSize="small" />
            {t("download")}
          </Button>
        )
      }
    </PDFDownloadLink>
  );
};

export default GeneratePdfGoals;
