/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import Select from "react-select";
import { getPreprogrammed } from "services/preProgrammed";
import { getTrainerClients } from "services/clients";
import useGetAll from "hooks/useGetAll";
import { getClientRoutines } from "services/customWorkout";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import useLang from "hooks/useLang";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import generateId from "helpers/generateId";
import StylesPrograms from "./Combine.module.css";
import ProgramList from "./ProgramList";

const Combine = ({ onClose, isOpen, days, setFiltered, client }) => {
  const { id_user } = useSelector(({ user }) => user);
  const { lang } = useLang();
  const [data, setData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [completed, setCompleted] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [nameWorkOut, setnameWorkOut] = useState("");
  const [typeList, setTypeList] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [nPages, setNPages] = useState(0);
  const {
    data: dataNameClient,
    isLoading,
    isError,
    refetch,
  } = useGetAll("clients", id_user, getTrainerClients);

  const handleGetPrepromamed = async () => {
    const resopnse = await getPreprogrammed({ lang, combine: true, page: 0, itemsPerPage: 10 });
    const programs = resopnse.preProgrammedWorkouts;
    setData(programs);
    setTypeList("programs");
    setNPages(resopnse.lastPage !== 0 ? resopnse.lastPage : 1);
    setCurrentPage(resopnse.page);
  };
  const getProgram = async (page) => {
    const resopnse = await getPreprogrammed({
      name: searchValue,
      lang,
      combine: true,
      page,
      itemsPerPage: 10,
    });
    const programs = resopnse.preProgrammedWorkouts;
    setData(programs);
    setNPages(resopnse.lastPage !== 0 ? resopnse.lastPage : 1);
    setCurrentPage(resopnse.page);
    document.getElementById("list").scroll({ top: 0, behavior: "smooth" });
  };
  const goToNextPage = () => {
    if (currentPage !== nPages) getProgram(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 0) getProgram(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 0) getProgram(0);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) getProgram(nPages - 1);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (typeList === "programs") getProgram();
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const options = dataNameClient?.map(({ name, _id }) => {
    const returnOptions = {
      value: _id,
      label: name,
    };
    return returnOptions;
  });

  const SelectClientList = async ({ value }) => {
    const response = await getClientRoutines(value);
    setData(response);
    setTypeList("clients");
  };

  const handleClickProgram = (program) => {
    const FormatDays = [...program.sessions];
    const array = FormatDays?.map(({ week }) => week);
    const newarray = [...new Set(array)];

    const dataFilter = newarray?.map((item) =>
      program.sessions?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );
    setProgramData(dataFilter);
    setnameWorkOut(program.workout_name.en);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    // const destinationIndex = result.destination.index;

    // Move day in the list source to
    if (result.source.droppableId === "tasks" && result.destination.droppableId === "completed") {
      const arrayOfObjects = programData.reduce((acc, val) => acc.concat(val), []);
      const [removed] = arrayOfObjects.splice(sourceIndex, 1);
      setProgramData([...programData]);
      setCompleted([...completed, removed]);
    }

    // Move the list completo to tasks again
    if (result.source.droppableId === "completed" && result.destination.droppableId === "tasks") {
      const [removed] = completed.splice(sourceIndex, 1);
      setCompleted([...completed]);
      console.log("removedddd", removed);
      setProgramData([...programData, removed]);
    }
  };

  const handleSave = () => {
    const ReasginedWeeks = [...weeks, [...completed]];
    const order = ReasginedWeeks.map((arr, i) => {
      const arrMap = arr.map((obj, j) => {
        const subArrMap = {
          ...obj,
          day: 1 + j,
          week: 1 + i,
          _id: generateId(),
          exercises: obj.exercises.map((i) => {
            const setIdExercise = {
              ...i,
              _id: generateId(),
              sets: i.sets.map((e) => {
                const newId = {
                  ...e,
                  _id: generateId(),
                };
                return newId;
              }),
            };
            return setIdExercise;
          }),
        };
        return subArrMap;
      });
      return arrMap;
    });
    setWeeks(order);
    setCompleted([]);
    setProgramData([]);
  };

  const handleSaveAll = () => {
    const lastWeek = [...days].pop();
    const IncWeek = 1 + lastWeek.week;
    const ReasginedWeeks = [...weeks];
    const order = ReasginedWeeks.map((arr, i) => {
      const arrMap = arr.map((obj) => {
        const subArrMap = {
          ...obj,
          id: generateId(),
          author: {
            id: generateId(),
            name: IncWeek + i,
            colors: {
              soft: "white",
              hard: "white",
            },
          },
          week: IncWeek + i,
          notes: {
            en: "",
            es: "",
          },
          date: null,
          session: "",
        };
        return subArrMap;
      });
      return arrMap;
    });
    const arrayOfObjects = order.reduce((acc, val) => acc.concat(val), []);
    const JoinData = days.concat(arrayOfObjects);
    const FormatDays = [...JoinData];
    const array = FormatDays?.map(({ week }) => week);
    const newarray = [...new Set(array)];
    const dataFilter = newarray?.map((item) =>
      JoinData?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );
    setFiltered(dataFilter);
    setWeeks([]);
    setCompleted([]);
    setProgramData([]);
    setData([]);
    onClose();
  };

  const handleClear = () => {
    setProgramData([]);
    setData([]);
    setWeeks([]);
    setCompleted([]);
    onClose();
    setNPages(0);
    setCurrentPage(0);
    setTypeList("");
  };

  return (
    <>
      <Modal returnFocusOnClose={false} onClose={() => handleClear()} size="6xl" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent height="700px">
          <ModalBody p={0}>
            <Box>
              <Box className={StylesPrograms.info} m={0} p={0}>
                <Box
                  bg="#eeeeee"
                  minHeight="700px"
                  width="600px"
                  className={StylesPrograms.list}
                  p={5}
                  overflowY="auto">
                  <div className={StylesPrograms.input_icon}>
                    <SearchIcon className={StylesPrograms.icon_search} />
                    <Input
                      value={searchValue}
                      onChange={({ target }) => setSearchValue(target.value)}
                      variant="flushed"
                      placeholder="Search"
                      borderRadius="20px"
                      pl={12}
                      border="1px solid gray"
                      backgroundColor="white"
                    />
                  </div>
                  {data
                    ?.filter(
                      (program) =>
                        new RegExp(searchValue, "gi").test(program.workout_name.en) ||
                        new RegExp(searchValue, "gi").test(program.workout_name.es),
                    )
                    ?.map((program) => (
                      <ProgramList
                        name={program.workout_name.en}
                        sub={program.source}
                        phase={program.phase || "1"}
                        createdDate={dayjs(program.created_at).format(
                          lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                        )}
                        handleClick={() => handleClickProgram(program)}
                        isActive={programData.workout_name?.en === program.workout_name.en}
                        key={program._id}
                      />
                    ))}
                  {typeList === "programs" ? (
                    <nav>
                      <ul className="pagination">
                        <li>
                          <IconButton
                            isRound
                            variant="ghost"
                            colorScheme="blackAlpha"
                            aria-label="Done"
                            fontSize="20px"
                            disabled={currentPage === 0}
                            icon={<FirstPageIcon />}
                            onClick={goToFirstPage}
                          />
                        </li>
                        <li>
                          <IconButton
                            isRound
                            variant="ghost"
                            colorScheme="blackAlpha"
                            aria-label="Done"
                            fontSize="20px"
                            disabled={currentPage === 0}
                            icon={<ArrowBackIosIcon />}
                            onClick={goToPrevPage}
                          />
                        </li>
                        <li>
                          {currentPage + 1}/{nPages}
                        </li>
                        <li>
                          <IconButton
                            isRound
                            variant="ghost"
                            colorScheme="blackAlpha"
                            aria-label="Done"
                            fontSize="20px"
                            disabled={currentPage + 1 === nPages}
                            icon={<ArrowForwardIosIcon />}
                            onClick={goToNextPage}
                          />
                        </li>
                        <li>
                          <IconButton
                            isRound
                            variant="ghost"
                            colorScheme="blackAlpha"
                            aria-label="Done"
                            fontSize="20px"
                            disabled={currentPage + 1 === nPages}
                            icon={<LastPageIcon />}
                            onClick={goToLastPage}
                          />
                        </li>
                      </ul>
                    </nav>
                  ) : null}
                </Box>
                <Box p={5} className={StylesPrograms.box}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" flexDirection="row" justifyContent="start">
                      <Text fontWeight="semibold" fontSize="20px">
                        Add more exercises to{" "}
                        <span style={{ color: "#b42519" }}>{client.split("___")[0]}</span> from:
                      </Text>
                    </Box>
                    <Box>
                      <ModalCloseButton />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" my={5}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="start"
                      style={{ gap: "20px" }}>
                      <Button
                        width={36}
                        _focus={{
                          bg: "#b42519",
                          border: "1px solid #b42519",
                          color: "white",
                        }}
                        onClick={() => handleGetPrepromamed()}>
                        Pre-programed
                      </Button>
                      <Box width={44}>
                        <Select options={options} onChange={SelectClientList} />
                      </Box>
                    </Box>
                    <Box mr={5}>
                      {weeks.length >= 1 ? (
                        <Button
                          onClick={() => handleSaveAll()}
                          bg="#6bca21"
                          border="none"
                          color="white"
                          _hover={{ background: "#6bca21", border: "mome" }}>
                          Save Changes
                        </Button>
                      ) : (
                        <Button disabled bg="#eeeeee">
                          Save Changes
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Text
                    letterSpacing="0.5em"
                    textTransform="uppercase"
                    color="#b42519"
                    fontWeight="semibold">
                    {!nameWorkOut ? "select a routine" : nameWorkOut}
                  </Text>
                  <Box display="flex" flexDirection="row" overflowY="auto">
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="tasks">
                        {(provided) => (
                          <>
                            <div
                              className="tasks"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: "white",
                                width: "300px",
                                height: "500px",
                                padding: "1rem",
                                overflowY: "auto",
                              }}>
                              <h2>Weeks</h2>
                              {Object.keys(programData).length >= 1 && (
                                <>
                                  <Accordion defaultIndex={[0]} allowMultiple>
                                    {programData?.map((task, index) => (
                                      <AccordionItem my={3} bg="white" color="black">
                                        <h2>
                                          <AccordionButton
                                            border="1px solid gray"
                                            borderRadius="10px">
                                            <Box
                                              as="span"
                                              flex="1"
                                              textAlign="left"
                                              color="gray"
                                              fontWeight="semibold">
                                              <Text fontWeight="bold">Week {index + 1}</Text>
                                              <Text>Week name</Text>
                                            </Box>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel p={0}>
                                          {task?.map((e) => (
                                            <Draggable
                                              key={e._id}
                                              draggableId={e._id}
                                              index={e._id}>
                                              {(provided) => (
                                                <Box
                                                  className="task"
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  ref={provided.innerRef}
                                                  padding="1rem"
                                                  borderRadius="10px"
                                                  border="1px solid lightgray"
                                                  bg="#f6f6f6"
                                                  mt={2}
                                                  mb={2}
                                                  key={e._id}>
                                                  <Text
                                                    display="inline"
                                                    color="gray"
                                                    fontWeight="bold"
                                                    mb={2}>
                                                    Day {e.day}
                                                  </Text>
                                                  {e.exercises?.map((exercose) => (
                                                    <Box
                                                      key={exercose._id}
                                                      display="flex"
                                                      justifyContent="space-between">
                                                      <Text fontSize="sm">
                                                        {exercose.id_exercise.name[lang]}
                                                      </Text>
                                                      <Text color="#b42519">
                                                        {exercose.sets.length} Sets
                                                      </Text>
                                                    </Box>
                                                  ))}
                                                </Box>
                                              )}
                                            </Draggable>
                                          ))}
                                        </AccordionPanel>
                                      </AccordionItem>
                                    ))}
                                  </Accordion>
                                </>
                              )}
                              {provided.placeholder}
                            </div>
                          </>
                        )}
                      </Droppable>
                      <Droppable droppableId="completed">
                        {(provided) => (
                          <div
                            className="completed"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              background: "#f6f6f6",
                              width: "300px",
                              padding: "1rem",
                              height: "500px",
                              overflowY: "auto",
                            }}>
                            <h2>Seleted</h2>
                            {completed?.map((task, index) => (
                              <Draggable key={task._id} draggableId={task._id} index={index}>
                                {(provided) => (
                                  <Box
                                    className="task"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    padding="1rem"
                                    borderRadius="10px"
                                    border="1px solid lightgray"
                                    bg="white"
                                    mt={2}
                                    mb={2}>
                                    <Text
                                      display="inline"
                                      color="gray"
                                      fontWeight="semibold"
                                      mb={2}>
                                      Day {task.day}
                                    </Text>
                                    {task.exercises?.map((exercose) => (
                                      <Box
                                        key={exercose._id}
                                        display="flex"
                                        justifyContent="space-between">
                                        <Text fontSize="sm">{exercose.id_exercise.name[lang]}</Text>
                                        <Text color="#b42519">{exercose.sets.length} Sets</Text>
                                      </Box>
                                    ))}
                                  </Box>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                    <Box width="300px" height="500px" overflowY="auto">
                      {completed.length >= 1 && (
                        <Button
                          bg="#f07300"
                          borderRadius="8px"
                          color="white"
                          w="full"
                          onClick={handleSave}>
                          Confirm Week
                        </Button>
                      )}
                      <Accordion defaultIndex={[0]} allowMultiple>
                        {weeks?.map((obj, i) => (
                          <AccordionItem my={3} bg="white" color="black">
                            <h2>
                              <AccordionButton border="1px solid gray" borderRadius="10px">
                                <Box
                                  as="span"
                                  flex="1"
                                  textAlign="left"
                                  color="gray"
                                  fontWeight="semibold">
                                  Week {i + 1}
                                  <Text>Week name</Text>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel p={0}>
                              {obj.map((e) => (
                                <Box
                                  padding="1rem"
                                  borderRadius="10px"
                                  border="1px solid lightgray"
                                  bg="white"
                                  mt={2}
                                  mb={2}>
                                  <Text display="inline" color="gray" fontWeight="semibold" mb={2}>
                                    Day {e.day}
                                  </Text>
                                  {e.exercises?.map((exercose) => (
                                    <Box
                                      key={exercose._id}
                                      display="flex"
                                      justifyContent="space-between">
                                      <Text fontSize="sm">{exercose.id_exercise.name[lang]}</Text>
                                      <Text color="#b42519">{exercose.sets.length} Sets</Text>
                                    </Box>
                                  ))}
                                </Box>
                              ))}
                            </AccordionPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Combine;
