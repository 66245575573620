/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import Select from "react-select";
import { CloseIcon } from "@chakra-ui/icons";
import { getPreprogrammed } from "services/preProgrammed";
import useGetAll from "hooks/useGetAll";
import { getTrainerClients } from "services/clients";
import { getClientRoutines } from "services/customWorkout";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import LoadingProgramList from "components/routines/dnd/AlternativeBoard/components/Combine/LoadingProgramList";
import dayjs from "dayjs";
import useLang from "hooks/useLang";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import generateId from "helpers/generateId";
import StylesPrograms from "./Combine.module.css";
import ProgramList from "./ProgramList";

const MoreProgramsExercises = ({
  onClose,
  isOpen,
  addMultipleExercise,
  onCloseMain,
  date,
  currentClient,
}) => {
  const { id_user } = useSelector(({ user }) => user);
  const { lang, t } = useLang();
  const [data, setData] = useState(null);
  const [programData, setProgramData] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [loadingData, setloadingData] = useState(false);
  const [activeButton, setactiveButton] = useState("");
  const [showSelectClient, setShowSelectClient] = useState(false);
  const [completed, setCompleted] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [nameWorkOut, setnameWorkOut] = useState({ es: "", en: "" });
  const [typeList, setTypeList] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [nPages, setNPages] = useState(0);
  const {
    data: dataNameClient,
    isLoading,
    isError,
    refetch,
  } = useGetAll("", id_user, getTrainerClients);

  const handleGetPrepromamed = async () => {
    setShowSelectClient(false);
    setactiveButton("preprogrammed");
    setloadingData(true);
    const resopnse = await getPreprogrammed({ lang, combine: true, page: 0, itemsPerPage: 10 });
    const programs = resopnse.preProgrammedWorkouts;
    setloadingData(false);
    setData(programs);
    setTypeList("programs");
    setNPages(resopnse.lastPage !== 0 ? resopnse.lastPage : 1);
    setCurrentPage(resopnse.page);
  };
  const getProgram = async (page) => {
    const resopnse = await getPreprogrammed({
      name: searchValue,
      lang,
      combine: true,
      page,
      itemsPerPage: 10,
    });
    const programs = resopnse.preProgrammedWorkouts;
    setData(programs);
    setNPages(resopnse.lastPage !== 0 ? resopnse.lastPage : 1);
    setCurrentPage(resopnse.page);
    document.getElementById("list").scroll({ top: 0, behavior: "smooth" });
  };
  const goToNextPage = () => {
    if (currentPage !== nPages) getProgram(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 0) getProgram(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 0) getProgram(0);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) getProgram(nPages - 1);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (typeList === "programs") getProgram();
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);
  const handleClear = () => {
    setProgramData([]);
    setData(null);
    setWeeks([]);
    setCompleted([]);
    onClose();
    setactiveButton("");
    setShowSelectClient(false);
    setNPages(0);
    setCurrentPage(0);
    setTypeList("");
  };

  const options = dataNameClient?.map(({ name, lastName, _id }) => {
    const returnOptions = {
      value: _id,
      label: `${name} ${lastName || ""}`,
    };
    return returnOptions;
  });

  const SelectClientList = async ({ value }) => {
    setloadingData(true);
    const response = await getClientRoutines(value);
    setloadingData(false);
    setData(response);
    setTypeList("clients");
  };

  const handleClickProgram = (program) => {
    const FormatDays = [...program.sessions];
    const array = FormatDays?.map(({ week }) => week);
    const newarray = [...new Set(array)];

    const dataFilter = newarray?.map((item) =>
      program.sessions?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );
    setProgramData(dataFilter);
    setnameWorkOut({
      en: program?.workout_name?.en || "",
      es: program?.workout_name?.es || "",
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Move day in the list source to
    if (result.source.droppableId === "tasks" && result.destination.droppableId === "completed") {
      const arrayOfObjects = programData.flat();
      const removed = arrayOfObjects.find(({ _id }) => _id === sourceIndex);
      setProgramData([...programData]);
      setCompleted([
        ...completed,
        {
          ...removed,
          _id: generateId(),
        },
      ]);
    }

    // Move the list completo to tasks again
    // if (
    //   result.source.droppableId === 'completed'
    //   && result.destination.droppableId === 'completed'
    // ) {
    //   const resultInitial = Array.from([...completed[result.source.droppableId]]);
    //   const [removed] = resultInitial.splice(sourceIndex, 1);
    //   resultInitial.splice(destinationIndex, 0, removed);
    //   const resultArr = {
    //     ...completed,
    //     [result.source.droppableId]: resultInitial,
    //   };
    //   console.log('take the day', resultArr);
    // }
  };

  const handleSave = () => {
    const ReasginedWeeks = [...weeks, ...completed];
    const order = ReasginedWeeks.map((obj, j) => ({
      exercises: obj.exercises.map((i) => ({
        ...i,
        _id: generateId(),
        sets: i.sets.map((e) => ({
          ...e,
          _id: generateId(),
        })),
      })),
    }));
    const flatOrder = order.flat();
    setWeeks(flatOrder);
    setCompleted([]);
    setProgramData([]);
  };

  const handleRemoveDaySelected = (i) => {
    setCompleted((prev) => prev.filter((element, index) => index !== i));
  };

  const handleRemoveDayConfirmed = (i) => {
    setWeeks((prev) => prev.filter((element, index) => index !== i));
  };

  const handleSaveAll = () => {
    addMultipleExercise(date, weeks);
    handleClear();
    onCloseMain();
  };

  return (
    <Modal
      onClose={() => {
        handleClear();
        onCloseMain();
      }}
      size="8xl"
      closeOnOverlayClick={false}
      isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent height="700px">
        <ModalBody p={0}>
          <Box>
            <Box className={StylesPrograms.info} m={0} p={0}>
              <Box
                bg="#eeeeee"
                minHeight="700px"
                width="600px"
                className={StylesPrograms.list}
                p={3}
                overflowY="auto"
                id="list">
                <div className={StylesPrograms.input_icon}>
                  <SearchIcon className={StylesPrograms.icon_search} />
                  <Input
                    value={searchValue}
                    onChange={({ target }) => setSearchValue(target.value)}
                    variant="flushed"
                    placeholder={t("search")}
                    borderRadius="20px"
                    pl={12}
                    border="1px solid gray"
                    backgroundColor="white"
                  />
                </div>
                {loadingData ? (
                  <LoadingProgramList />
                ) : (
                  <>
                    {data?.length <= 0 ? (
                      <p>{t("client_no_have_program")}</p>
                    ) : (
                      <>
                        {data
                          ?.filter(
                            (program) =>
                              new RegExp(searchValue, "gi").test(program.workout_name.en) ||
                              new RegExp(searchValue, "gi").test(program.workout_name.es),
                          )
                          ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                          ?.map((program) => (
                            <ProgramList
                              name={program.workout_name}
                              sub={program.source}
                              phase={program.phase || "1"}
                              createdDate={dayjs(program.created_at).format(
                                lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                              )}
                              handleClick={() => handleClickProgram(program)}
                              isActive={programData.workout_name?.en === program.workout_name.en}
                              key={program._id}
                            />
                          ))}
                        {typeList === "programs" ? (
                          <nav>
                            <ul className="pagination">
                              <li>
                                <IconButton
                                  isRound
                                  variant="ghost"
                                  colorScheme="blackAlpha"
                                  aria-label="Done"
                                  fontSize="20px"
                                  disabled={currentPage === 0}
                                  icon={<FirstPageIcon />}
                                  onClick={goToFirstPage}
                                />
                              </li>
                              <li>
                                <IconButton
                                  isRound
                                  variant="ghost"
                                  colorScheme="blackAlpha"
                                  aria-label="Done"
                                  fontSize="20px"
                                  disabled={currentPage === 0}
                                  icon={<ArrowBackIosIcon />}
                                  onClick={goToPrevPage}
                                />
                              </li>
                              <li>
                                {currentPage + 1}/{nPages}
                              </li>
                              <li>
                                <IconButton
                                  isRound
                                  variant="ghost"
                                  colorScheme="blackAlpha"
                                  aria-label="Done"
                                  fontSize="20px"
                                  disabled={currentPage + 1 === nPages}
                                  icon={<ArrowForwardIosIcon />}
                                  onClick={goToNextPage}
                                />
                              </li>
                              <li>
                                <IconButton
                                  isRound
                                  variant="ghost"
                                  colorScheme="blackAlpha"
                                  aria-label="Done"
                                  fontSize="20px"
                                  disabled={currentPage + 1 === nPages}
                                  icon={<LastPageIcon />}
                                  onClick={goToLastPage}
                                />
                              </li>
                            </ul>
                          </nav>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </Box>
              <Box p={5} className={StylesPrograms.box}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" flexDirection="row" justifyContent="start">
                    <Text fontWeight="semibold" fontSize="20px">
                      {t("add_more_exercise_title")}{" "}
                      <span style={{ color: "#b42519" }}>
                        {currentClient?.split("__")[0]
                          ? `${t("to")} ${currentClient?.split("__")[0]}`
                          : ""}
                      </span>
                      :
                    </Text>
                  </Box>
                  <Box>
                    <ModalCloseButton />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" my={5}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="start"
                    style={{ gap: "20px" }}>
                    <Button
                      bg={activeButton === "preprogrammed" && "#b42519"}
                      border="1px solid black"
                      color={activeButton === "preprogrammed" && "white"}
                      _focus={{
                        bg: "#b42519",
                        border: "1px solid #b42519",
                        color: "white",
                      }}
                      _hover={{
                        bg: "#b42519",
                        border: "1px solid #b42519",
                        color: "white",
                      }}
                      onClick={() => handleGetPrepromamed()}>
                      {t("from_a_preprogrammed")}
                    </Button>
                    <Button
                      bg={activeButton === "clients" && "#b42519"}
                      border="1px solid black"
                      color={activeButton === "clients" && "white"}
                      _focus={{
                        bg: "#b42519",
                        border: "1px solid #b42519",
                        color: "white",
                      }}
                      _hover={{
                        bg: "#b42519",
                        border: "1px solid #b42519",
                        color: "white",
                      }}
                      onClick={() => {
                        setShowSelectClient(true);
                        setactiveButton("clients");
                      }}>
                      {t("from_a_client")}
                    </Button>
                    {showSelectClient && (
                      <Box width={44}>
                        <Select
                          placeholder={t("select_a_client")}
                          options={options}
                          onChange={SelectClientList}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box mr={5}>
                    {weeks.length >= 1 ? (
                      <Button
                        onClick={() => handleSaveAll()}
                        bg="#6bca21"
                        border="none"
                        color="white"
                        _hover={{ background: "#6bca21", border: "mome" }}>
                        {t("import_exercises")}
                      </Button>
                    ) : (
                      <Button disabled bg="#eeeeee">
                        {t("import_exercises")}
                      </Button>
                    )}
                  </Box>
                </Box>
                <Text textTransform="uppercase" color="#b42519" fontWeight="semibold">
                  {!nameWorkOut[lang]
                    ? t("select_routine_word")
                    : nameWorkOut[lang] ?? nameWorkOut.en}
                </Text>
                <Box display="flex" flexDirection="row" overflowY="auto">
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="tasks">
                      {(provided) => (
                        <>
                          <div
                            className="tasks"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              background: "white",
                              width: "300px",
                              height: "500px",
                              padding: "1rem",
                              overflowY: "auto",
                            }}>
                            <h2>{t("list_week")}</h2>
                            {Object.keys(programData).length >= 1 && (
                              <>
                                <Accordion defaultIndex={[0]} allowMultiple>
                                  {programData?.map((task, index) => (
                                    <AccordionItem my={3} bg="white" color="black">
                                      <h2>
                                        <AccordionButton
                                          border="1px solid gray"
                                          borderRadius="10px">
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            color="gray"
                                            fontWeight="semibold">
                                            <Text fontWeight="bold">
                                              {t("list_week")} {index + 1}
                                            </Text>
                                            <Text>{t("week_name")}</Text>
                                          </Box>
                                          <AccordionIcon />
                                        </AccordionButton>
                                      </h2>
                                      <AccordionPanel p={0}>
                                        {task?.map((e) => (
                                          <Draggable key={e._id} draggableId={e._id} index={e._id}>
                                            {(provided) => (
                                              <Box
                                                className="task"
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                padding="1rem"
                                                borderRadius="10px"
                                                border="1px solid lightgray"
                                                bg="#f6f6f6"
                                                mt={2}
                                                mb={2}
                                                key={e._id}>
                                                <Text
                                                  display="inline"
                                                  color="gray"
                                                  fontWeight="bold"
                                                  mb={2}>
                                                  {t("list_day")} {e.day}
                                                </Text>
                                                {e.exercises?.map((exercose) => (
                                                  <Box key={exercose._id}>
                                                    <Text fontSize="sm">
                                                      {exercose?.id_exercise?.name[lang] || (
                                                        <span
                                                          style={{
                                                            textTransform: "uppercase",
                                                            color: "red",
                                                          }}>
                                                          {t("undefined_exercise")}
                                                        </span>
                                                      )}
                                                    </Text>
                                                    <Text color="#b42519" fontSize="12px">
                                                      {exercose.sets[0].load !== "" &&
                                                      exercose.sets[0].load !== "0" ? (
                                                        <>
                                                          {exercose.sets.length} Sets x{" "}
                                                          {exercose.sets?.map((s) => (
                                                            <span>
                                                              {s.load}
                                                              {s.typeWeight === "others"
                                                                ? ""
                                                                : s.typeWeight}
                                                              /{s.reps}
                                                              {s?.typeReps ?? ""},{" "}
                                                            </span>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {exercose.sets.length} Sets x{" "}
                                                          {exercose.sets[0].reps}
                                                          {exercose.sets[0].typeReps ?? ""}
                                                        </>
                                                      )}
                                                    </Text>
                                                  </Box>
                                                ))}
                                              </Box>
                                            )}
                                          </Draggable>
                                        ))}
                                      </AccordionPanel>
                                    </AccordionItem>
                                  ))}
                                </Accordion>
                              </>
                            )}
                            {provided.placeholder}
                          </div>
                        </>
                      )}
                    </Droppable>
                    <Droppable droppableId="completed">
                      {(provided) => (
                        <div
                          className="completed"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: "#f6f6f6",
                            width: "300px",
                            padding: "1rem",
                            height: "500px",
                            overflowY: "auto",
                          }}>
                          <h2>{t("seleted")}</h2>
                          {completed?.map((task, index) => (
                            <Draggable key={task._id} draggableId={task._id} index={index}>
                              {(provided) => (
                                <Box
                                  className="task"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  padding="1rem"
                                  borderRadius="10px"
                                  border="1px solid lightgray"
                                  bg="white"
                                  mt={2}
                                  mb={2}>
                                  <Box display="flex" justifyContent="space-between">
                                    <Text
                                      display="inline"
                                      color="gray"
                                      fontWeight="semibold"
                                      mb={2}>
                                      {t("list_day")} {task.day}
                                    </Text>
                                    <button
                                      type="button"
                                      className={StylesPrograms.btn_delete_element}
                                      onClick={() => handleRemoveDaySelected(index)}>
                                      <CloseIcon />
                                    </button>
                                  </Box>
                                  {task.exercises?.map((exercose) => (
                                    <Box key={exercose._id}>
                                      <Text fontSize="sm">{exercose.id_exercise.name[lang]}</Text>
                                      <Text color="#b42519" fontSize="12px">
                                        {exercose.sets[0].load !== "" &&
                                        exercose.sets[0].load !== "0" ? (
                                          <>
                                            {exercose.sets.length} Sets x{" "}
                                            {exercose.sets?.map((s) => (
                                              <span>
                                                {s.load}
                                                {s.typeWeight === "others" ? "" : s.typeWeight}/
                                                {s.reps}
                                                {s?.typeReps ?? ""},{" "}
                                              </span>
                                            ))}
                                          </>
                                        ) : (
                                          <>
                                            {exercose.sets.length} Sets x {exercose.sets[0].reps}
                                            {exercose.sets[0].typeReps ?? ""}
                                          </>
                                        )}
                                      </Text>
                                    </Box>
                                  ))}
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <Box width="300px" height="500px" overflowY="auto">
                    {completed.length >= 1 && (
                      <Button
                        bg="#f07300"
                        borderRadius="8px"
                        color="white"
                        w="full"
                        onClick={handleSave}>
                        Confirm exercises
                      </Button>
                    )}
                    {weeks?.map((obj, i) => (
                      <Box
                        padding="1rem"
                        borderRadius="10px"
                        border="1px solid lightgray"
                        bg="white"
                        mt={2}
                        mb={2}>
                        <button
                          type="button"
                          className={StylesPrograms.btn_delete_element}
                          onClick={() => handleRemoveDayConfirmed(i)}>
                          <CloseIcon />
                        </button>
                        {obj.exercises?.map((exercose) => (
                          <Box key={exercose._id}>
                            <Text fontSize="sm">{exercose.id_exercise.name[lang]}</Text>
                            <Text color="#b42519" fontSize="12px">
                              {exercose.sets[0].load !== "" && exercose.sets[0].load !== "0" ? (
                                <>
                                  {exercose.sets.length} Sets x{" "}
                                  {exercose.sets?.map((s) => (
                                    <span>
                                      {s.load}
                                      {s.typeWeight === "others" ? "" : s.typeWeight}/{s.reps}
                                      {s?.typeReps ?? ""},{" "}
                                    </span>
                                  ))}
                                </>
                              ) : (
                                <>
                                  {exercose.sets.length} Sets x {exercose.sets[0].reps}
                                  {exercose.sets[0].typeReps ?? ""}
                                </>
                              )}
                            </Text>
                          </Box>
                        ))}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MoreProgramsExercises;
