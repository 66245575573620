/* eslint-disable react/no-children-prop */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import useLang from "hooks/useLang";
import {
  Box,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Select,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import DatePicker from "react-date-picker";
import { CloseIcon, PhoneIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useEditWeekContext } from "./EditWeek";
import EditExerciseEditWeek from "./EditExerciseEditWeek";

const EditWeekContent = ({ isEdit, inicialValue, date }) => {
  const { lang, t } = useLang();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { changeDate, changeSession, changeNote, addRow, deleteExercise, changeValue, deleteRow } =
    useEditWeekContext();

  const handleChangeSession = ({ target }) => changeSession(inicialValue, target.value);

  return (
    <Box>
      <Box>
        <InputGroup bg="#f6f6f6" marginBottom={5}>
          <InputLeftAddon children="Date" />
          <DatePicker
            id={inicialValue._id}
            onChange={(e) => changeDate(inicialValue, e)}
            value={inicialValue?.date || ""}
            format={lang === "es" ? "dd/MM/yyy" : "MM/dd/yyy"}
            style={{ width: "100%" }}
          />
        </InputGroup>
        <InputGroup bg="#f6f6f6" marginBottom={5}>
          <InputLeftAddon children="Session" />
          <Input
            type="text"
            bg="#f6f6f6"
            id={inicialValue._id}
            onChange={handleChangeSession}
            value={inicialValue.session}
          />
        </InputGroup>
        {isOpen && (
          <EditExerciseEditWeek
            isOpen={isOpen}
            onClose={onClose}
            date={inicialValue.exercises?.map((exercise) => exercise)}
          />
        )}
        {inicialValue.exercises?.map((exercise, idx) => (
          <article>
            <InputGroup>
              <InputGroup bg="white" border="1px solid #E3E3E3" marginBottom={5}>
                <InputLeftAddon children="Exercise" color="#b42519" />
                <Input
                  type="tel"
                  bg="white"
                  color="gray"
                  id={inicialValue._id}
                  onChange={handleChangeSession}
                  value={exercise.id_exercise.name[lang]}
                  disabled
                  textAlign="right"
                />
              </InputGroup>
              <IconButton
                icon={<DeleteIcon />}
                variant="ghost"
                color="gray"
                onClick={() => deleteExercise(date, idx)}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup bg="white" border="1px solid #E3E3E3" marginBottom={5}>
                <InputLeftAddon children="Number of Sets" color="#b42519" />
                <Input
                  type="tel"
                  bg="white"
                  color="gray"
                  id={inicialValue._id}
                  onChange={handleChangeSession}
                  value={exercise.sets.length}
                  disabled
                  textAlign="right"
                />
                <IconButton
                  icon={<AddIcon />}
                  variant="ghost"
                  color="gray"
                  onClick={() => addRow(date, idx)}
                />
              </InputGroup>
            </InputGroup>
            <div>
              {exercise.sets?.map((s) => (
                <Box>
                  <InputGroup>
                    <InputGroup
                      bg="white"
                      border="1px solid #E3E3E3"
                      marginBottom={5}
                      marginRight={1}>
                      <InputLeftAddon children="Loads" color="#b42519" />
                      <Input
                        type="tel"
                        bg="white"
                        color="gray"
                        value={s.load}
                        onChange={({ target }) =>
                          changeValue({ ...s, [target.id]: target.value }, date, idx)
                        }
                        id="load"
                        textAlign="center"
                        paddingLeft={0}
                        paddingRight={1}
                      />
                      <Select
                        variant="flushed"
                        value={s.typeWeight}
                        id="typeWeight"
                        onChange={({ target }) =>
                          changeValue({ ...s, [target.id]: target.value }, date, idx)
                        }>
                        <option value="kg">kg</option>
                        <option value="lb">lb</option>
                        <option value="others">other</option>
                      </Select>
                    </InputGroup>
                    <InputGroup
                      bg="white"
                      border="1px solid #E3E3E3"
                      marginBottom={5}
                      marginLeft={1}>
                      <InputLeftAddon children="Reps" color="#b42519" />
                      <Input
                        type="tel"
                        bg="white"
                        color="gray"
                        value={s.reps}
                        id="reps"
                        onChange={({ target }) =>
                          changeValue({ ...s, [target.id]: target.value }, date, idx)
                        }
                        textAlign="center"
                        paddingLeft={0}
                        paddingRight={1}
                      />
                      <Select
                        variant="flushed"
                        value={s.typeReps}
                        id="typeReps"
                        onChange={({ target }) =>
                          changeValue({ ...s, [target.id]: target.value }, date, idx)
                        }>
                        <option value="reps">reps</option>
                        <option value="sec">sec</option>
                      </Select>
                    </InputGroup>
                    {exercise.sets.length <= 1 ? (
                      <IconButton icon={<DeleteIcon />} variant="ghost" disabled color="gray" />
                    ) : (
                      <IconButton
                        icon={<DeleteIcon />}
                        variant="ghost"
                        color="gray"
                        onClick={() => deleteRow(date, idx, s.set_number)}
                      />
                    )}
                  </InputGroup>
                </Box>
              ))}
            </div>
          </article>
        ))}
        <Button
          color="gray"
          border="1px solid #E3E3E3"
          borderRadius="8px"
          marginBottom={5}
          bg="transparent"
          _hover={{ color: "#b42519", border: "1px solid #b42519" }}
          leftIcon={<AddIcon />}
          onClick={onOpen}>
          Add Exercise
        </Button>
      </Box>
    </Box>
  );
};

export default EditWeekContent;
