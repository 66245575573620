import React from "react";
import { func, shape, string } from "prop-types";

import styles from "./ClientContainer.module.css";
import ClientLabel from "../ClientLabel";
import ClientProfile from "../ClientProfile";
import ClientHistory from "../ClientHistory";
import ClientPrograms from "../ClientPrograms";

const ClientContainer = ({ client, tab, setTab, allClient, handleUpdateClient, setClients }) => (
  <section className={styles.container}>
    <ClientLabel setTab={setTab} tab={tab} client={client} />
    {tab.isSelected && tab.value === "programs" && (
      <ClientPrograms
        idClient={client._id}
        name={client.name}
        clientInfo={client}
        clients={allClient}
      />
    )}
    {tab.isSelected && tab.value === "history" && <ClientHistory idClient={client._id} />}
    {tab.isSelected && tab.value === "profile" && (
      <ClientProfile
        client={client}
        setTab={setTab}
        handleUpdateClient={handleUpdateClient}
        setClients={setClients}
      />
    )}
  </section>
);

ClientContainer.propTypes = {
  tab: shape({}).isRequired,
  setTab: func.isRequired,
  client: shape({
    _id: string.isRequired,
  }).isRequired,
  setClients: func.isRequired,
  allClient: shape([]).isRequired,
  handleUpdateClient: func.isRequired,
};

export default ClientContainer;
