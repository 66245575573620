/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useRef } from "react";

import { useParams } from "react-router-dom";
// ? External Dependencies
import { useTranslation } from "react-i18next";
import {
  Button,
  Menu as MenuLang,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Avatar,
  Text,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  useToast,
  Divider,
  AbsoluteCenter,
  Input,
  IconButton,
} from "@chakra-ui/react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "react-qr-code";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// ? local Dependencies
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import { getRoutine } from "services/customWorkout";
import UsaLogo from "assets/eeuu.png";
import SpainLogo from "assets/spain.png";
import useLang from "hooks/useLang";
import languages from "utils/languages";
import moment from "moment/moment";
import i18next from "i18next";
import styles from "./sharedRoutine.module.scss";
import logo from "../../../assets/3.png";
import ExerciseCard from "./exerciseCard";

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

const SharedRoutine = () => {
  const { t, lang } = useLang();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [workout, setWorkout] = useState([]);
  const [weekShow, setWeekShow] = useState(0);
  const [dayShow, setDayShow] = useState(0);
  const [listOfWeeks, setListOfWeeks] = useState([0]);
  const [listOfDays, setListOfDays] = useState([0]);
  const [loading, setLoading] = useState(true);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const currentYear = new Date().getFullYear();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const onLanguageChange = (code) => {
    i18next.changeLanguage(code);
  };

  const onGetRoutine = async () => {
    try {
      const response = await getRoutine(id);
      if (response.data) throw new Error("something went wrong");
      setData(response);
      /* CONVERT TO WEEKS */
      const FormatDays = [...response.sessions];
      const array = FormatDays?.map(({ week }) => week);
      const newarray = [...new Set(array)];
      const convertToArray = newarray?.map((item) =>
        response.sessions?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
      );
      /*  */
      const dataSlice =
        response.sharedFilter > 0
          ? convertToArray.slice(convertToArray.length - response.sharedFilter)
          : convertToArray;
      setListOfWeeks([...dataSlice.keys()]);
      setWorkout(dataSlice);
      const firstTime = localStorage.getItem("firstTimeShared");
      if (!firstTime) {
        localStorage.setItem("firstTimeShared", "true");
        setListOfDays([...dataSlice[0].keys()]);
      } else {
        setWeekShow(dataSlice.length - 1);

        setListOfDays([...dataSlice[dataSlice.length - 1].keys()]);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const selectWeek = (week) => {
    setIsComponentVisible(!isComponentVisible);
    setWeekShow(week);
    setDayShow(0);
    setListOfDays([...workout[week].keys()]);
  };
  useEffect(() => {
    onGetRoutine();
  }, []);

  const copyClipboard = () => {
    const copyText = document.getElementById("routine-url");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(copyText.value);
    toast({
      position: "bottom",
      render: () => (
        <Box color="white" p={3} bg="black" textAlign="center">
          {t("copiedClipboard")}
        </Box>
      ),
    });
  };
  const isDayComplete = () => {
    return workout?.[weekShow]?.[dayShow].completed || false;
  };
  return (
    <section className={styles.root}>
      <header>
        <div className={styles.header}>
          <div onClick={onOpen}>
            <InfoIcon />
          </div>
          <img src={logo} alt="logo" className={styles.logo} />
          <MenuLang>
            <MenuButton as={Button} variant="ghost" fontSize="40px" padding={0} borderRadius="full">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  name="english lang"
                  src={lang === "en" ? UsaLogo : SpainLogo}
                  width="30px"
                  height="30px"
                />
              </Box>
            </MenuButton>
            <MenuList zIndex="101">
              {languages.map(({ code, name, countryCode }) => (
                <MenuItem key={code} color="ptText.500" onClick={() => onLanguageChange(code)}>
                  <span className={`flag-icon flag-icon-${countryCode}`} />
                  <span>{name}</span>
                </MenuItem>
              ))}
            </MenuList>
          </MenuLang>
        </div>
        <div className={styles.filter}>
          <div className={styles.weekMenu} ref={ref}>
            <div
              className={styles.weekSelector}
              onClick={() => setIsComponentVisible(!isComponentVisible)}>
              Week {weekShow + 1} {isComponentVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </div>
            {isComponentVisible && (
              <div className={`${styles.innerWeekSelector}`}>
                <ul>
                  {listOfWeeks.map((week, index) => (
                    <li
                      className={`${weekShow === week ? styles.active : null}`}
                      onClick={() => selectWeek(week)}>
                      Week {week + 1}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className={styles.daySelector}>
            {listOfDays.map((day, index) => (
              <div
                className={`${styles.day} ${dayShow === day ? styles.active : null}`}
                onClick={() => setDayShow(day)}>
                {/* <div className={styles.checkIcon}>
                {isDayComplete() ? (
                  <CheckCircleIcon color="success" fontSize="inherit" />
                ) : (
                  <RadioButtonUncheckedIcon fontSize="inherit" />
                )}
              </div> */}
                <div className={styles.title}>Day</div>
                <div>{day + 1}</div>
              </div>
            ))}
          </div>
        </div>
      </header>

      <div className={styles.content}>
        <Flex justifyContent="space-between">
          <Heading size="md">{data.workout_name?.[lang] || ""}</Heading>
          {workout?.[weekShow]?.[dayShow].date && workout?.[weekShow]?.[dayShow].date !== "" ? (
            <Text fontSize="16px" textAlign="end">
              {moment(workout?.[weekShow]?.[dayShow].date).format(
                lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
              )}
            </Text>
          ) : null}
        </Flex>

        <Text fontSize="12px" my={4}>
          {workout?.[weekShow]?.[dayShow].notes?.[lang]}
        </Text>
        {workout?.[weekShow]?.[dayShow].exercises?.map((exercise) => (
          <div key={exercise._id}>
            <ExerciseCard exercise={exercise} week={weekShow} day={dayShow} />
          </div>
        ))}
      </div>

      <div className={styles.footer}>
        <div className={styles.term}>
          <a href="#a">{t("privacy_policy")}</a>
          <a href="#a">{t("terms_use")}</a>
          <a href="#a">Legal</a>
        </div>
        <span>
          Copyright © {currentYear} The Institute Of Human Performance | {t("copyright_text")}
        </span>
      </div>
      {/*  */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            style={{
              backgroundColor: "white",
              top: "0px",
              right: "0px",
              borderRadius: "50%",
            }}
          />
          <ModalBody my={4}>
            <div className={styles.usersInfo}>
              <div>
                <div className={styles.trainerInfo}>
                  <Avatar
                    size="lg"
                    name={data.id_trainer?.name || "PT"}
                    src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${
                      data.id_trainer?.pic !== "" ? data.id_trainer?.pic : data.id_trainer?.logo
                    }`}
                  />
                  <div>
                    <span className={styles.badge}>Trainer</span>
                    <Heading size="md" as="h4">
                      {data.id_trainer?.name || ""}
                    </Heading>
                    <Text>{t(data.id_trainer?.speciality || "")}</Text>
                  </div>
                </div>
                <Text textAlign="center" fontSize="12px" color="gray">
                  {data.id_trainer?.phone}
                </Text>
                <Text textAlign="center" fontSize="12px" color="gray">
                  {data.id_trainer?.address?.city}, {data.id_trainer?.address?.state}.{" "}
                  {data.id_trainer?.address?.street1} {data.id_trainer?.address?.street2}.{" "}
                  {data.id_trainer?.address?.zip}{" "}
                </Text>
              </div>
              <Box position="relative" my={8}>
                <Divider />
                <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs" color="gray">
                  {t("clientInfo")}
                </AbsoluteCenter>
              </Box>
              <div>
                <Heading textAlign="center" size="md" as="h4">
                  {data.id_client?.name}
                </Heading>
                <Text textAlign="center">{data.id_client?.email}</Text>
              </div>
            </div>
            <Box position="relative" my={8}>
              <Divider />
              <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs" color="gray">
                {t("shareProgram")}
              </AbsoluteCenter>
            </Box>
            <Text textAlign="center" my={4} fontSize="xs" colorScheme="gray">
              {t("scanQr")}
            </Text>
            <div style={{ height: "auto", margin: "0 auto", maxWidth: 120, width: "100%" }}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={`${window.location.protocol}//${window.location.host}/shared-routine/${id}`}
                viewBox="0 0 256 256"
              />
            </div>
            <Box position="relative" my={6}>
              <Text textAlign="center" bg="white" px="4" fontSize="xs">
                {t("orCopyLink")}
              </Text>
            </Box>
            <Flex gridGap={4} alignItems="center">
              <Input
                disabled
                readOnly
                value={`${window.location.protocol}//${window.location.host}/shared-routine/${id}`}
                cursor="pointer"
                id="routine-url"
                fontSize="12px"
                onClick={copyClipboard}
              />
              <IconButton
                isRound
                size="sm"
                colorScheme="red"
                variant="ghost"
                onClick={copyClipboard}
                icon={<ContentCopyIcon fontSize="inherit" />}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </section>
  );
};

export default SharedRoutine;
