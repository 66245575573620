/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";

import { Badge, Flex, Heading, IconButton, Image, Input, Skeleton, Text } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "components/planning/style.css";
import CalendarScreen from "components/calendar/CalendarScreen";

import notFound from "assets/notfound.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import { getTrainerClients } from "services/clients";
import { eventStartLoading } from "../../actions/events";

const Planning = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [clientId, setClientId] = useState(null);
  const { id_user } = useSelector(({ user }) => user);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [nPages, setNPages] = useState(0);
  const { t } = useTranslation();

  const selectClient = (id) => {
    setClientId(id);
    dispatch(eventStartLoading(id));
  };
  const getClients = async () => {
    try {
      const response = await getTrainerClients(id_user);
      if (response.data) throw new Error(response.data.error);
      setClients(response);
      setNPages(Math.ceil(response.length / recordsPerPage));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    document.title = "Perfect Trainer App | Planning";
    const sidebar = document.getElementsByClassName("sidebar")[0];

    if (sidebar) sidebar.setAttribute("class", "sidebar collapse");
    getClients();
    dispatch(eventStartLoading(clientId));
    return () => {
      if (sidebar) sidebar.setAttribute("class", "sidebar");
    };
  }, []);

  useEffect(() => {
    setNPages(Math.ceil(clients.length / recordsPerPage));
  }, [search]);
  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) setCurrentPage(nPages);
  };
  return (
    <div>
      <div className="planing-container">
        <div className="users">
          <ul className="list">
            <li
              className={!clientId ? "active" : ""}
              onClick={() => selectClient(null)}
              aria-hidden="true">
              {t("myPlanning")}
            </li>
            {!isLoading ? (
              <>
                <Input
                  variant="filled"
                  placeholder={t("search_client")}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  w="100%"
                />
                {clients
                  ?.filter(
                    (item) =>
                      item.name?.toLowerCase().includes(search.toLowerCase()) ||
                      item.lastName?.toLowerCase().includes(search.toLowerCase()),
                  )
                  .slice(indexOfFirstRecord, indexOfLastRecord)
                  .map((client, index) => (
                    <li
                      key={index}
                      className={clientId === client._id ? "active" : ""}
                      onClick={() => selectClient(client._id || null)}
                      aria-hidden="true">
                      <Badge
                        id="profile"
                        bg={!client.isGuest ? "#6bca21" : "#b42519"}
                        color="white"
                        borderRadius="20px"
                        padding="3px 15px">
                        {!client.isGuest ? t("client") : t("guest")}
                      </Badge>
                      <div>
                        {client.name} {client.lastName}
                      </div>
                    </li>
                  ))}
                {clients?.filter(
                  (item) =>
                    item.name?.toLowerCase().includes(search.toLowerCase()) ||
                    item.lastName?.toLowerCase().includes(search.toLowerCase()),
                ).length <= 0 && (
                  <Flex flexDirection="column" alignItems="center" justifyContent="center">
                    <Image src={notFound} w="10rem" alt="not found" />
                    <Heading size="md">{t("notFoundClient")}</Heading>
                  </Flex>
                )}
              </>
            ) : (
              <>
                <Flex w="100%" flexDirection="column" gridGap={4}>
                  <Skeleton height="40px" w="100%" />
                  <Skeleton height="40px" w="100%" />
                  <Skeleton height="40px" w="100%" />
                  <Skeleton height="40px" w="100%" />
                  <Skeleton height="40px" w="100%" />
                </Flex>
              </>
            )}
          </ul>

          <nav>
            <ul className="pagination">
              <li>
                <IconButton
                  isRound
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="Done"
                  fontSize="20px"
                  disabled={currentPage === 1}
                  icon={<FirstPageIcon />}
                  onClick={goToFirstPage}
                />
              </li>
              <li>
                <IconButton
                  isRound
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="Done"
                  fontSize="20px"
                  icon={<ArrowBackIosIcon />}
                  onClick={goToPrevPage}
                  disabled={currentPage === 1}
                />
              </li>
              <li>
                {search !== "" ? 1 : currentPage}/{nPages <= 0 ? 1 : nPages}
              </li>
              <li>
                <IconButton
                  isRound
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="Done"
                  fontSize="20px"
                  icon={<ArrowForwardIosIcon />}
                  onClick={goToNextPage}
                  disabled={currentPage >= nPages}
                />
              </li>
              <li>
                <IconButton
                  isRound
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="Done"
                  fontSize="20px"
                  disabled={currentPage === nPages}
                  icon={<LastPageIcon />}
                  onClick={goToLastPage}
                />
              </li>
            </ul>
          </nav>
        </div>

        <div className="calendar">
          <CalendarScreen client={clientId} isEdit />
        </div>
      </div>
    </div>
  );
};

export default Planning;
