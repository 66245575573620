import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, ButtonGroup } from "@chakra-ui/react";
import { getDashboardStats } from "services/dashboard";
import useLang from "hooks/useLang";
import { Link, useHistory } from "react-router-dom";
import styles from "./Dashboard.module.scss";

import clients from "../../assets/icons/ico_clients.svg";
import TodoList from "./todoList";
import Features from "./features";
import LatestEntries from "./latestEntries";

import programs from "../../assets/icons/ico_programs.svg";
import assessment from "../../assets/icons/ico_assessment.svg";
import templates from "../../assets/icons/ico_templates.svg";
import CertificateEs from "../../assets/certificate_es.png";
import CertificateEn from "../../assets/certificate_en.png";

const Dashboard = () => {
  const { t, lang } = useLang();
  const [stats, setStats] = useState({});
  const history = useHistory();
  const { id_user } = useSelector(({ user }) => user);

  const redirectToClientPage = () => {
    history.push({
      pathname: "/user/clients",
      state: {
        modalAdd: true,
      },
    });
  };

  const redirectToCreateProgram = () => {
    history.push({
      pathname: "/user/create",
      state: {
        mode: "preprograms",
      },
    });
  };

  const redirectToCreateExercise = () => {
    history.push({
      pathname: "/user/exercises",
      state: {
        add: true,
        mode: 'add'
      },
    });
  };

  const getStats = async () => {
    try {
      const response = await getDashboardStats(id_user);
      if (response.data) throw new Error(response.data.error);
      setStats(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = "Perfect Trainer App | Dashboard";
    getStats();
  }, []);
  return (
    <>
      <div className={styles.headerInfo}>
        <div>
          <h2 className={styles.title}>{t("plan_today")}</h2>
          <ButtonGroup className={styles.actions} variant="outline" margin="2rem 0px">
            <Button
              borderColor="#b42519"
              _hover={{ bg: "#b42519", color: "white" }}
              onClick={redirectToClientPage}>
              {t("add_client")}
            </Button>
            <Button
              borderColor="#b42519"
              _hover={{ bg: "#b42519", color: "white" }}
              onClick={redirectToCreateExercise}>
              {t("add_exercise")}
            </Button>
            <Button
              as={Link}
              borderColor="#b42519"
              _hover={{ bg: "#b42519", color: "white" }}
              to="/user/evaluation"
              id="template/admin">
              {t("cretate_assesstments")}
            </Button>
            <Button
              onClick={redirectToCreateProgram}
              borderColor="#b42519"
              _hover={{ bg: "#b42519", color: "white" }}>
              {t("add_program")}
            </Button>
          </ButtonGroup>
          <hr />
          <div className={styles.cards}>
            <Box as={Link} to="/user/clients" className={styles.card}>
              <img src={clients} alt="icon" />
              <span className={styles.number}>{stats.clients ?? 0}</span>
              <span>{t("clients")}</span>
            </Box>
            <Box as={Link} to="/user/programs/preprograms" className={styles.card}>
              <img src={programs} alt="icon" />
              <span className={styles.number}>{stats.programs ?? 0}</span>
              <span>{t("programs")}</span>
            </Box>
            <Box as={Link} to="/user/evaluation" className={styles.card}>
              <img src={assessment} alt="icon" />
              <span className={styles.number}>{stats.assessments ?? 0}</span>
              <span>{t("evaluation")}</span>
            </Box>
            <Box as={Link} to="/user/programs/template" className={styles.card}>
              <img src={templates} alt="icon" />
              <span className={styles.number}>{stats.templates ?? 0}</span>
              <span>{t("templates")}</span>
            </Box>
          </div>
        </div>
        <a
          href={
            lang === "en"
              ? "https://www.ihpproshop.com/certification"
              : "https://es.ihpproshop.com/certification"
          }
          target="_blank"
          rel="noopener noreferrer">
          <img
            className={styles.banner}
            style={{ marginBottom: "-3.4rem" }}
            src={lang === "en" ? CertificateEn : CertificateEs}
            alt="banner"
          />
        </a>
      </div>
      <hr />
      <div className={styles.extras}>
        <TodoList />
        <div>
          <Features />
          <LatestEntries />
        </div>
      </div>
    </>
  );
};
export default Dashboard;
