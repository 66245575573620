/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { shape, string } from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import notificationDisplay from "utils/notificationDisplay";
import useLang from "hooks/useLang";
import useGetAll from "hooks/useGetAll";
import { getClientRoutines, updateRoutine } from "services/customWorkout";
import AddIcon from "@mui/icons-material/Add";
import ClientRoutineDisplay from "../ClientRoutineDisplay";
import RoutineCard from "../RoutineCard";
import styles from "./ClientPrograms.module.css";
import NoProgramsDisplay from "../Features/NoProgramsDisplay";
import {
  container,
  btnGroup,
  title,
  card,
  subtitle,
} from "../Features/NoProgramsDisplay.module.css";
import CustomImg from "../../../../assets/routine/ico_custom.png";
import TemplateImg from "../../../../assets/routine/ico_template.png";
import PreprogramImg from "../../../../assets/routine/ico_preprogram.png";

const ClientPrograms = ({ idClient, name, clients, clientInfo }) => {
  const [isTypeSelected, setIsTypeSelected] = useState(false);
  const [programData, setProgramData] = useState({});
  const [showRenderPdf, setShowRenderPdf] = useState({
    _id: "",
    state: false,
  });
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure();
  const {
    isOpen: isOpenNewModal,
    onOpen: onOpenNewModal,
    onClose: onCloseNewModal,
  } = useDisclosure();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { lang, t } = useLang();
  const [verifyBetween, setVerifyBetween] = useState(false);
  const [copyAlertData, setCopyAlertData] = useState([]);
  const [nextProgram, setnextProgram] = useState(null);
  const config = (workType) => ({
    pathname: "/user/routines",
    state: {
      client: `${name}___${idClient}`,
      workType,
      clientInfo,
    },
  });

  const getRoutines = async () => {
    try {
      const response = await getClientRoutines(idClient);
      if (response.data) throw new Error(response.data.error);
      setData(response);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      notificationDisplay(err.message, "something went wrong", "error");
    }
  };

  const handleUpdateDnd = async (routineId, info) => {
    const prevState = { ...programData, sessions: info };
    try {
      await updateRoutine(routineId, { sessions: info });
      // queryClient.invalidateQueries("routines");
      // getRoutines();
      setShowRenderPdf({
        _id: "",
        state: false,
      });
      const index = data.findIndex((a) => a._id === programData._id);
      const newData = [...data];
      newData.splice(index, 1, prevState);
      setData(newData);
      setProgramData(prevState);
    } catch (error) {
      notificationDisplay(dispatch, error.message, "error");
      return console.log(error);
    }
  };
  const updateSharedFilter = async () => {
    try {
      queryClient.invalidateQueries("routines");
      // getRoutines();
    } catch (error) {
      return console.log(error);
    }
  };

  const compareArrays = (arr1, arr2) => {
    if (arr1?.length !== arr2?.length) {
      return false;
    }

    for (let i = 0; i < arr1?.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }

    return true;
  };

  const handleClickProgram = (program) => {
    let daysMutate = copyAlertData.flat();
    let daysUpdated = daysMutate.map((obj) => {
      let newObj = { ...obj };
      delete newObj.id;
      delete newObj.author;
      return newObj;
    });
    if (daysUpdated?.length === 0) {
      setShowRenderPdf({
        _id: "",
        state: false,
      });
      setProgramData(program);
      return;
    }

    if (compareArrays(programData.sessions, daysUpdated) && verifyBetween === false) {
      setShowRenderPdf({
        _id: "",
        state: false,
      });
      setCopyAlertData([]);
      setProgramData(program);
    } else if (!compareArrays(programData.sessions, daysUpdated) || verifyBetween === true) {
      onOpen();
      setnextProgram(program);
    }
  };

  const confirmSave = async () => {
    try {
      await updateRoutine(programData._id, { sessions: copyAlertData.flat() });
      setShowRenderPdf({
        _id: "",
        state: false,
      });
      setCopyAlertData([]);
      setVerifyBetween(false);
      setProgramData(nextProgram);
      setnextProgram(null);
    } catch (error) {
      notificationDisplay(dispatch, error.message, "error");
    }
    onClose();
  };

  useEffect(async () => {
    await getRoutines();
    if (data?.length > 0) {
      handleClickProgram(data[data?.length - 1]);
    }
  }, []);

  useEffect(async () => {
    if (data?.length > 0) {
      handleClickProgram(data[data?.length - 1]);
    }
  }, [data]);

  if (!isLoading && data?.length <= 0)
    return <NoProgramsDisplay clientInfo={clientInfo} name={name} idClient={idClient} />;
  if (isLoading)
    return (
      <>
        <Flex gridGap="1rem" mt={4}>
          <Flex gridGap="1rem" flexDirection="column">
            <Skeleton height="150px" w="224px" />
            <Skeleton height="150px" w="224px" />
            <Skeleton height="150px" w="224px" />
          </Flex>
          <Flex gridGap="1rem">
            <Skeleton height="750px" w="310px" />
            <Skeleton height="750px" w="310px" />
            <Skeleton height="750px" w="310px" />
          </Flex>
        </Flex>
      </>
    );

  return (
    <section>
      <Flex gridGap="1rem">
        <div className={styles.listExternal}>
          {!clientInfo.isGuest && (
            <Button colorScheme="teal" leftIcon={<AddIcon />} onClick={() => onOpenNewModal()}>
              {t("create_new_program")}
            </Button>
          )}

          {data
            ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            ?.map((program) => (
              <RoutineCard
                name={program.workout_name.en}
                sub={program.source}
                phase={program.phase || "1"}
                createdDate={dayjs(program.created_at).format(
                  lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                )}
                handleClick={() => handleClickProgram(program)}
                isActive={programData.workout_name?.en === program.workout_name.en}
                key={program._id}
                getRoutines={getRoutines}
                queryClient={queryClient}
                setProgramData={setProgramData}
                handleEdit={(e) => {
                  setProgramData(e);
                  getRoutines();
                  queryClient.invalidateQueries("routines");
                }}
                routineId={program._id}
                programData={program}
              />
            ))}
        </div>
        <Box>
          <ClientRoutineDisplay
            days={programData?.sessions}
            routineId={programData._id}
            sharedFilter={programData.sharedFilter}
            updateSharedFilter={updateSharedFilter}
            clients={clients}
            setVerifyBetween={setVerifyBetween}
            verifyBetween={verifyBetween}
            clientInfo={clientInfo}
            nameWorkout={programData.workout_name}
            handleUpdateDnd={handleUpdateDnd}
            nameClientCurrent={name}
            setcopyAlertData={setCopyAlertData}
            showRenderPdf={showRenderPdf}
            allDataCurrentProgram={programData}
            setProgramData={setProgramData}
            setShowRenderPdf={setShowRenderPdf}>
            <Box className="client-list-drawer">
              <Flex gridGap="10px" alignItems="center">
                <Button size="sm" colorScheme="teal" onClick={onOpenDrawer}>
                  {t("changeOrAdd")}
                </Button>
                <Text fontSize="sm" fontWeight="bold">
                  {programData?.workout_name?.[lang]}
                </Text>
              </Flex>
            </Box>
          </ClientRoutineDisplay>
        </Box>
      </Flex>

      {isOpen && (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t("delete_confirmation")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p>{t("confirm_save_or_discard")}</p>
            </ModalBody>
            <ModalFooter>
              <Box
                display="flex"
                flexDirection="column"
                w="full"
                gridGap={2}
                justifyContent="center"
                alignItems="center">
                <Button
                  w="70%"
                  bg="#b42519"
                  color="white"
                  _hover={{ bg: "white", border: "1px solid #b42519", color: "black" }}
                  onClick={() => onClose()}>
                  {t("cancel")}
                </Button>
                <Button
                  w="70%"
                  bg="#6bca21"
                  color="white"
                  _hover={{ bg: "#6bca21" }}
                  onClick={() => confirmSave()}>
                  {t("save")}
                </Button>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Drawer isOpen={isOpenDrawer} placement="right" onClose={onCloseDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader mb="1rem" />
          <DrawerBody>
            <div className={styles.list}>
              {!clientInfo.isGuest && (
                <Button colorScheme="teal" leftIcon={<AddIcon />} onClick={() => onOpenNewModal()}>
                  {t("create_new_program")}
                </Button>
              )}

              {data
                ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                ?.map((program) => (
                  <RoutineCard
                    name={program.workout_name.en}
                    sub={program.source}
                    phase={program.phase || "1"}
                    createdDate={dayjs(program.created_at).format(
                      lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                    )}
                    handleClick={() => handleClickProgram(program)}
                    isActive={programData.workout_name?.en === program.workout_name.en}
                    key={program._id}
                    getRoutines={getRoutines}
                    queryClient={queryClient}
                    setProgramData={setProgramData}
                    handleEdit={(e) => {
                      setProgramData(e);
                      getRoutines();
                      queryClient.invalidateQueries("routines");
                    }}
                    routineId={program._id}
                    programData={program}
                  />
                ))}
            </div>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onCloseDrawer}>
              {t("close")}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal onClose={onCloseNewModal} isOpen={isOpenNewModal} isCentered size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <Flex gridGap="10px" flexWrap="wrap" justifyContent="center">
              <div className={card}>
                <p className={title}>{t("from_a_client")}</p>
                <img src={TemplateImg} alt="evaluation program" />

                <Button
                  w="100%"
                  mt="1rem"
                  as={Link}
                  to={() => config("fromclient")}
                  variant="outline"
                  colorScheme="teal">
                  {t("start")}
                </Button>
              </div>
              <div className={card}>
                <p className={title}>{t("from_scratch")}</p>
                <img src={CustomImg} alt="scratch program" />
                <Button
                  w="100%"
                  mt="1rem"
                  as={Link}
                  to={() => config("custom")}
                  variant="outline"
                  colorScheme="teal">
                  {t("start")}
                </Button>
              </div>
              <div className={card}>
                <p className={title}>{t("preprogrammed_workout")}</p>
                <img src={PreprogramImg} alt="preprogrammed program" />
                <Button
                  w="100%"
                  mt="1rem"
                  as={Link}
                  to={() => config("preprogrammed")}
                  variant="outline"
                  colorScheme="teal">
                  {t("start")}
                </Button>
              </div>
              <div className={card}>
                <p className={title}>{t("template_workout")}</p>
                <img src={TemplateImg} alt="template program" />
                <Button
                  w="100%"
                  mt="1rem"
                  as={Link}
                  to={() => config("template")}
                  variant="outline"
                  colorScheme="teal">
                  {t("start")}
                </Button>
              </div>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => onCloseNewModal()}>{t("close")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </section>
  );
};

ClientPrograms.propTypes = {
  idClient: string.isRequired,
  name: string.isRequired,
  clients: shape([]).isRequired,
  clientInfo: shape({}).isRequired,
};

export default ClientPrograms;
